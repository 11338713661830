import React, { useEffect, useState,  } from 'react';
import { useTranslation } from 'react-i18next';

import InputsFields from 'components/specialJourneys/inputsFields';
import ScheduleInformation from 'components/specialJourneys/scheduleInformation';
import Input from 'components/specialJourneys/input';
import CustomHead from 'components/shared/customHead';
import Frame from 'components/shared/frame';
import Loading from 'components/shared/loading';
import ProcessingModal from 'components/shared/processingModal';
import CustomDialog from 'components/shared/customDialog';
import Button from 'components/shared/button';
import { SearchTextArea, SearchSelect } from 'components/shared/searchbox';

import useAxios from 'hooks/axios';
import useContent from 'hooks/content';

import { useApplication } from 'hooks/application';
import useRedis from 'hooks/cms/redis';
import mapSpecialJourneys from 'hooks/cms/mappers/mapSpecialJourneys';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import mapper from './mapper';
import './translations';

export default () => {
    useStyles(classes, globalStyles);
    const { t } = useTranslation('specialJourneys');
    const axios = useAxios();
    const { getSpecialJourneys } = useContent();
    const { getCache } = useRedis();
    const [cmsData, setCmsData] = useState();
    const [radioChecked, setRadioChecked] = useState(false);
    const [values, setValues] = useState({});
    const [showProcessingModal, setShowProcessingModal] = useState(false);
    const [showThankyouModal, setShowThankyouModal] = useState(false);
    const [response, setResponse] = useState();
    const { idPv } = useApplication();

    const enableStrapi = process.env.FT_ENABLE_STRAPI_SPECIAL_JOURNEYS;

    useEffect(() => {
        const getData = async () => {
            let content = await getSpecialJourneys();

            if(!content.metadata.UrlCanonica){
                content.metadata.UrlCanonica=window.location.href;
            }

            setCmsData(content);
        }
        const getContent = async () => {
            const cms = await getCache({key:'specialJourneysQuery',idPv:idPv});
            const content = mapSpecialJourneys(cms);
            setCmsData(content);
        };
        
        if(enableStrapi) {
            getContent();
        } else {
            getData();
        }
    }, [])

    const handleChange = event => {
        const name = event.target.name;
        const value = event.target.value;
        setValues({ ...values, [name]: value });
    };

    const handleSelectChange = ({ value, name }) => setValues({ ...values, [name]: value });
    const handlerDate = (item, elem) => setValues({ ...values, [elem]: item });

    const handleRadioChange = event => {
        const name = event.target.name;
        const value = event.target.value;
        setValues({ ...values, [name]: value });
        setRadioChecked(!radioChecked);
    }

    const formValid = () => {
        return values.CantidadPasajeros
            && values.CiudadOrigen
            && values.ProvinciaOrigen
            && values.PaisOrigen
            && values.CiudadDestino
            && values.ProvinciaDestino
            && values.PaisDestino
            && values.HorarioPartidaDesdeElOrigen
            && values.HorarioPartidaDesdeElDestino
            && values.NombreInstitucionParaLaQuePideServicio
            && values.NombreDatosParaContacto
            && values.TelefonoDeContacto1
            && values.MailDeContacto1;
    }

    const submitForm = () => {
        if (formValid()) {
            setShowProcessingModal(true)

            axios
                .post('Contenido/ViajesEspeciales', { model: mapper.modelEmail(values) })
                .then(res => {
                    setResponse(mapper.response(res.data))
                    setShowThankyouModal(true);
                })
                .finally(() => setShowProcessingModal(false))
        }
    }

    const handleSubmit = ev => {
        ev.preventDefault()
        submitForm()
    }

    return (
        cmsData
            ? <>
                <CustomHead metadata={cmsData.metadata} />
                <div className={classes.headerText}>
                    <h1> { cmsData.title } </h1>
                    <div dangerouslySetInnerHTML={{ __html: cmsData.description }} />
                </div>

                <form noValidate onSubmit={handleSubmit}>
                    <Frame data-testid='frame' title={cmsData.subtitle}>
                        <span> {`* ${t('requiredFields')}`} </span>
                        <div className={classes.elementsContainer}>
                            <div className={classes.paper}>
                                <div className={globalStyles.flexJustifyCenter}>
                                    <div>
                                        <Input
                                            required
                                            label={t('numberOfPassengers')}
                                            name='CantidadPasajeros'
                                            placeholder={`${t('numberOfPassengers')} *`}
                                            handleChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <InputsFields 
                                    title={t('origin')}
                                    labels={[ t('street'), t('city'), t('province'), t('country') ]}
                                    names={[ 'CalleOrigen', 'CiudadOrigen', 'ProvinciaOrigen', 'PaisOrigen' ]}    
                                    required={[ false, true, true, true ]}
                                    handleChange={handleChange}
                                />
                                <InputsFields 
                                    title={t('destiny')}
                                    labels={[ t('street'), t('city'), t('province'), t('country') ]}
                                    names={[ 'CalleDestino', 'CiudadDestino', 'ProvinciaDestino', 'PaisDestino' ]}
                                    required={[ false, true, true, true ]}
                                    handleChange={handleChange}
                                />
                            </div>
                            <div className={classes.paper}>
                                <h3 className={classes.formSubtitle}>
                                    {
                                        values && 
                                        values.CiudadOrigen && 
                                        values.ProvinciaOrigen && 
                                        values.PaisOrigen
                                            ? `${t('departureFromTheOrigin')} ${values.CiudadOrigen}, ${values.ProvinciaOrigen}, ${values.PaisOrigen}`
                                            : t('departureFromTheOrigin')
                                    }
                                </h3>
                                <ScheduleInformation 
                                    pickerId='FechaPartidaDesdeElOrigen'
                                    selectName='MomentoPartidaDesdeElOrigen'
                                    textFieldName='HorarioPartidaDesdeElOrigen'
                                    handlerDate={handlerDate}
                                    handleChange={handleChange}
                                    handleSelectChange={handleSelectChange}
                                    selectedItem={values['MomentoPartidaDesdeElOrigen']}
                                />
                                <h3 className={classes.formSubtitle}>
                                    {
                                        values &&
                                        values.CiudadDestino && 
                                        values.ProvinciaDestino && 
                                        values.PaisDestino
                                            ? `${t('departureFromTheDestination')} ${values.CiudadDestino}, ${values.ProvinciaDestino}, ${values.PaisDestino}`
                                            : `${t('departureFromTheDestination')}`
                                    }
                                </h3>
                                <ScheduleInformation 
                                    pickerId='FechaArriboAlPuntoDeOrigen'
                                    selectName='MomentoPartidaDesdeElDestino'
                                    textFieldName='HorarioPartidaDesdeElDestino'
                                    handlerDate={handlerDate}
                                    handleChange={handleChange}
                                    handleSelectChange={handleSelectChange}
                                    selectedItem={values['MomentoPartidaDesdeElDestino']}
                                />
                            </div>
                            <div className={classes.paper}>
                                <h3 className={classes.formSubtitle}>
                                    {
                                        values && 
                                        values.CiudadDestino && 
                                        values.ProvinciaDestino
                                            ? `${t('onceAtDestination')} ${values.CiudadDestino}, ${values.ProvinciaDestino}`
                                            : t('onceAtDestination')
                                    }
                                </h3>

                                <div className={classes.destinationMovementsContainer}>
                                    <div>
                                        <h4> { t('destinationMovements') } </h4>
                                        <div className={classes.radios}>
                                            <label>
                                                <input 
                                                    type='radio'
                                                    name='MovimientosEnDestinoPermanecerUnaVezEnDestino' 
                                                    value='Si'
                                                    checked={radioChecked}
                                                    onChange={handleRadioChange} 
                                                />
                                                { t('yes') }
                                            </label>
                                            <label>
                                                <input 
                                                    type='radio'
                                                    name='MovimientosEnDestinoPermanecerUnaVezEnDestino' 
                                                    value='No'
                                                    checked={!radioChecked}
                                                    onChange={handleRadioChange} 
                                                />
                                                { t('no') }
                                            </label>
                                        </div>
                                    </div>
                                    <div>
                                        <SearchTextArea 
                                            onBlur={handleChange} 
                                            placeholder={t('destinationMovementsDetail')}
                                            rows={3}
                                            disabled={!values.MovimientosEnDestinoPermanecerUnaVezEnDestino || values.MovimientosEnDestinoPermanecerUnaVezEnDestino === 'No'}
                                            name='MovimientosDescripcion' 
                                        />
                                    </div>
                                </div>
                                <div className={classes.paper}>
                                    <div className={classes.destinationMovementsRowSelects}>
                                        <div>
                                            <span> { t('carRemainWithTheGroup') } </span>
                                            <SearchSelect
                                                placeHolder={t('select')} 
                                                data={[
                                                    { name: `${t('yes')}`, id: 'Si' },
                                                    { name: `${t('no')}`, id: 'No' },
                                                ]}
                                                onChange={handleSelectChange}
                                                name='ElCocheDebePermanecerUnaVezEnDestino'
                                                required
                                            />
                                        </div>
                                        <div>
                                            <span> { t('carCategory') } </span>
                                            <SearchSelect
                                                placeHolder={t('select')} 
                                                data={[
                                                    { name: 'Común con aire', id: 'Común con aire' },
                                                    { name: 'Semicama', id: 'Semicama' },
                                                    { name: 'Cama', id: 'Cama' },
                                                    { name: 'Suite', id: 'Suite' },
                                                    { name: 'Ejecutivo', id: 'Ejecutivo' },
                                                ]}
                                                onChange={handleSelectChange}
                                                name='CategoriaDelCoche'
                                                required
                                            />
                                        </div>
                                        <div>
                                            <span>{ t('catetingOnBoard') } </span>
                                            <SearchSelect
                                                placeHolder={t('select')} 
                                                data={[
                                                    { name: `${t('coffeeJuice')}`, id: 'Cafe / Jugo' },
                                                    { name: `${t('foodOnBoard')}`, id: 'Viandas' },
                                                    { name: `${t('restaurantMeals')}`, id: 'Comidas en parador' },
                                                    { name: `${t('noCatering')}`, id: 'Sin Catering' },
                                                ]}
                                                onChange={handleSelectChange}
                                                name='Catering'
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.destinationMovementsRowSelects}>
                                        <div>
                                            <span> { t('provideFoodToDrivers') } </span>
                                            <SearchSelect
                                                placeHolder={t('select')} 
                                                data={[
                                                    { name: `${t('yes')}`, id: 'Si' },
                                                    { name: `${t('no')}`, id: 'No' },
                                                ]}
                                                onChange={handleSelectChange}
                                                name='AlojanChoferesUnaVezEnDestino'
                                                required
                                            />
                                        </div>
                                        <div>
                                            <span> { t('passengerCharacteristics') } </span>
                                            <SearchSelect
                                                placeHolder={t('select')} 
                                                data={[
                                                    { name: `${t('escolaresJovenes')}`, id: 'Escolares y/o Jovenes' },
                                                    { name: `${t('adults')}`, id: 'Adultos' },
                                                    { name: `${t('elderly')}`, id: 'Jubilados' },
                                                    { name: `${t('families')}`, id: 'Familias' },
                                                    { name: `${t('other')}`, id: 'Otros' },
                                                ]}
                                                onChange={handleSelectChange}
                                                name='CaracteristicasDeLosPasajeros'
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.paper}>
                                <h3 className={classes.formSubtitle}>
                                    { t('institutionTitle') }
                                </h3>
                                <div className={globalStyles.flexJustifyCenter}>
                                    <div>
                                        <Input
                                            required
                                            placeholder={`${t('institutionName')}*`}
                                            name='NombreInstitucionParaLaQuePideServicio'
                                            onBlur={handleChange}
                                        />
                                    </div>
                                </div>
                                <InputsFields
                                    showTitle={false}
                                    labels={[ t('street'), t('city'), t('province'), t('country') ]}
                                    names={[ 
                                        'CalleInstitucionParaLaQuePideServicio', 
                                        'CuidadInstitucionParaLaQuePideServicio', 
                                        'ProvinciaInstitucionParaLaQuePideServicio', 
                                        'PaisInstitucionParaLaQuePideServicio' 
                                    ]}
                                    required={[ false, false, false, false ]}
                                    handleChange={handleChange}
                                />
                            </div>
                            <div className={classes.paper}>
                                <h3 className={classes.formSubtitle}> 
                                    { t('contactInformation') } 
                                </h3>
                                <div className={globalStyles.flexJustifyCenter}>
                                    <div>
                                        <Input
                                            required
                                            placeholder={`${t('nameAndSurname')}*`}
                                            name='NombreDatosParaContacto'
                                            onBlur={handleChange}
                                        />
                                    </div>
                                </div>
                                <InputsFields
                                    showTitle={false}
                                    labels={[ t('street'), t('city'), t('province'), t('country') ]}
                                    names={
                                        [ 
                                            'CalleDatosParaContacto', 
                                            'CuidadDatosParaContacto', 
                                            'ProvinciaDatosParaContacto', 
                                            'PaisDatosParaContacto' 
                                        ]
                                    }
                                    required={[ true, true, true, true ]}
                                    handleChange={handleChange}
                                />
                                <InputsFields
                                    showTitle={false}
                                    labels={[
                                        t('phoneContact'),
                                        t('mailContact'),
                                        t('province'),
                                        t('country')
                                    ]}
                                    names={[ 
                                        'TelefonoDeContacto1', 
                                        'MailDeContacto1', 
                                        'TelefonoDeContacto2', 
                                        'MailDeContacto2' 
                                    ]}
                                    required={[ true, true, false, false ]}
                                    handleChange={handleChange}
                                />
                            </div>
                            <div className={classes.paper}>
                                <h3 className={classes.formSubtitle}> 
                                    { t('observations') } 
                                </h3>
                                <div>
                                    <SearchTextArea
                                        placeholder={`${t('observations')}...`}
                                        rows={3}
                                        name='Observaciones'
                                        onBlur={handleChange}
                                    />
                                </div>
                                <h3 className={classes.formSubtitle}> 
                                    { t('howDidYouMeetUs') } 
                                </h3>
                                <div>
                                    <SearchSelect
                                        placeHolder={t('select')} 
                                        data={[
                                            { name: `${t('socialNetworks')}`, id: 'Redes Sociales' },
                                            { name: `${t('bySearchEngines')}`, id: 'Por buscadores de Internet (Google,Yahoo, Bing)' },
                                            { name: `${t('onPlataforma10Com')}`, id: 'En www.plataforma10.com' },
                                            { name: `${t('onPlataforma10Net')}`, id: 'En www.plataforma10.net' },
                                            { name: `${t('trainingForSellers')}`, id: 'Por capacitación para vendedores' },
                                            { name: `${t('atPointSale')}`, id: 'En un Punto de venta de Plataforma10' },
                                            { name: `${t('byMailWithNews')}`, id: 'Por recibir un mail con la noticia' },
                                            { name: `${t('byReferred')}`, id: 'Por conocidos o referidos' },
                                            { name: `${t('other')}`, id: 'Otro' },
                                        ]}
                                        onChange={handleSelectChange}
                                        name='ComoNosConocio'
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={classes.buttonContainer}>
                            <Button disabled={!formValid()} onClick={handleSubmit}>
                                { t('sendRequest') }
                            </Button>
                        </div>
                    </Frame>
                    <div className={classes.headerText} dangerouslySetInnerHTML={{ __html: cmsData.footerText }} />
                </form>
                <>
                    { showProcessingModal && <ProcessingModal /> }
                </>
                <>
                    {
                        showThankyouModal && response &&
                            <CustomDialog 
                                data-testid='customDialog'
                                title={response.title}
                                onClose={() => setShowThankyouModal(false)}
                            >
                                <p> { response.detail } </p>
                            </CustomDialog>
                    }
                </>
              </>
            : <Loading />
    )
}