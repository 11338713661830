import mapMetaData from "./mapMetaData";

const mapSpecialJourneys = data => {
    if(!data || data.viajesEspeciales.length == 0) return null;

    const { 
        titulo,
        subtitulo,
        textoFooter,
        descripcion,
        metadata
        } = data.viajesEspeciales[0];
    
    return{
        title:titulo,
        subtitle:subtitulo,
        description:descripcion,
        footerText:textoFooter,
        metadata:mapMetaData(metadata),

    }
}

export default mapSpecialJourneys;