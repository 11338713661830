// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".a408b95f402fe3fbb31bf99374a4ed0c-scss{height:300px;width:100%}.a408b95f402fe3fbb31bf99374a4ed0c-scss ._8acdb2d4edffc455984dfa13dc9aee53-scss{width:25px}.a408b95f402fe3fbb31bf99374a4ed0c-scss ._8acdb2d4edffc455984dfa13dc9aee53-scss ._6dab4a67127f527d94bddaebe8881ebe-scss{fill:var(--primary-main)}\n", ""]);
// Exports
exports.locals = {
	"container": "a408b95f402fe3fbb31bf99374a4ed0c-scss",
	"iconContainer": "_8acdb2d4edffc455984dfa13dc9aee53-scss",
	"icon": "_6dab4a67127f527d94bddaebe8881ebe-scss"
};
module.exports = exports;
