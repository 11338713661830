// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._28adf2dc1bea84051e98dfc2a4ac4ee8-scss{display:grid;text-transform:uppercase}._0a3d14073d027d1ffe6ac95b4930457b-scss{text-align:center;grid-gap:15px}._4ea3275c8d2e996ad032448f6883a040-scss{color:var(--black-dark)}\n", ""]);
// Exports
exports.locals = {
	"root": "_28adf2dc1bea84051e98dfc2a4ac4ee8-scss",
	"selectorContainer": "_0a3d14073d027d1ffe6ac95b4930457b-scss",
	"label": "_4ea3275c8d2e996ad032448f6883a040-scss"
};
module.exports = exports;
