import React, { useState, useEffect, useCallback } from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import useStyles from "isomorphic-style-loader/useStyles";
import classes from "./style.module.scss";

const   NewSearchSelect = ({
placeHolder,
data,
onChange,
selectedItem,
hasGroup,
name,
...props
}) => {
  const [value, setValue] = useState(selectedItem ? selectedItem : "");
  const [dropdownOptions, setDropdownOptions] = useState([]);
  useStyles(classes);


  const getGrouppedOptions = useCallback(() => {
    return Object.keys(data).map((item) => {
      return {
        type: "group",
        name: item,
        items: data[item].map((element) => ({
          value: element.id,
          label: element.name,
        })),
      };
    });
  }, [data]);

  const getOptions = useCallback(() => {
    return data.map((item) => {
      return { label: item.name, value: item.id };
    });
  }, [data]);

  useEffect(() => {
    const options = hasGroup ? getGrouppedOptions() : getOptions();
    setDropdownOptions(options);
  }, [data]);

  const handleChange = (event) => {
    setValue(event.target.value);
    onChange(name ? { value: target.value, name: name } : parseInt(event.target.value));
  };

const renderOptions = () => {
  if (hasGroup) {
    return (
      <>
        <option value="" disabled selected className={classes.option}>
          {placeHolder}
        </option>
        {dropdownOptions.map((option, index) => (
          <optgroup label={option.name} key={index}> 
            {option.items.map((option,index)=>(
              <option key={index} value={option.value}>
              {option.label}
              </option>
            ))};
            </optgroup>
        ))}
      </>
    );
  } else {
    return (
      <>
        <option value="" disabled selected className={classes.option}>
          {placeHolder}
        </option>
        {dropdownOptions.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </>
    );
  }
};

return (
    <div className={classes.customDropdown}>
    <select className={classes.styledSelect} onChange={handleChange}>
        {renderOptions()}
    </select>
    </div>
  );
};

NewSearchSelect.defaultProps = {
  hasGroup: false,
};

  export default NewSearchSelect;
