export default {
    product: 'voos',
    searchTickets: 'Busca vôos',
    searchTicketsSeo: 'procurar',
    searchTicketsNow: 'Encontre seu vôo',
    search:'Procurar',
    from:'Origem',
    to:'Destino',
    departure: 'Ida',
    return:'Volta',
    errorMessageSameStops: 'Origem e Destino não podem ser iguais',
    inputPlaceholder: 'Entre cidade',
    datePlaceholder: 'Selecione data'
}