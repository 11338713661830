export default {
    product: 'remises',
    pay: 'Pagar',
    paySeo: 'pagar',
    title: 'Cotizá tu traslado en remis',
    quote: 'Cotizar',
    from:'Origen',
    to:'Destino',
    date: 'Fecha',
    time: 'Horario',
    price: 'Precio',
    errorMessageSameStops: 'Origen y Destino no pueden ser iguales',
    inputPlaceholder: 'Ingrese dirección o lugar',
    datePlaceholder: 'Seleccione fecha',
    timePlaceholder: 'Seleccione horario',
    noCoverageError: 'No se encontraron servicios disponibles para la busqueda.',
    invalidDateError: 'El horario seleccionado debe ser posterior a: ',
    title:'Atención',
    comments: 'Observaciones',
    commentsPlaceholder: 'Aquí debe ingresar cualquier comentario sobre el viaje, como por ejemplo, el piso y departamento',
    passengers: 'Pasajeros'
}