export default {
    searchTickets: 'Buscar Hoteles',
    title: 'Encontre o seu hotel agora',
    from:'Hotel, cidade ou destino',
    departureDate: 'Entrada',
    returnDate:'Saída',
    selectRooms:'Selecione quartos',
    url: 'Hoteis',
    af:'9003111&',
    pa: 'RE'
}