import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { SearchLabel, SearchInput, SearchPicker, SearchButton, SearchContainer, SearchSelectPassenger } from 'components/shared/searchbox';

import useStops from 'hooks/stops';

import { validateForm, generateUrl } from 'shared/flights/searchbox';
import { errorCompare } from 'shared/searchBox/common';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import './translations';

export default  () => {
	useStyles(classes);
	const history = useHistory();
	const { t } = useTranslation('flightsSearchbox');
	const stops = useStops('ApiGeoInformacion/GenerarLocalidadesBuscador');

	const [trip, setTrip] = useState({});
	const [formValid, setFormValid] = useState(false);
	const { departure, destination, departureDate, returnDate } = trip;

	useEffect(() => {
		const valid = validateForm(trip);
		setFormValid(valid);
	}, [stops, trip]);

	const handlerItemSelected = (item, elem) => setTrip({ ...trip, [elem]: item });
	const handlerPassengersChange = passengers => setTrip({ ...trip, ...passengers });

	const handleSearch = event => {
		event.preventDefault();
		const baseUrl = generateUrl(trip, t('product'), t('searchTicketsSeo'));
		history.push(baseUrl)
	};

	return (
		<SearchContainer 
			onSubmit={handleSearch} 
			title={t('searchTicketsNow')} 
			gap='10px' 
		>
			<div className={classes.container}>
				<div className={classes.inputs}>
					<SearchLabel  
						label={t('from')}
						htmlFor='departure' 
						iconName='map-marker-alt' 
					/>
					<SearchInput 
						field='departure'
						id='departure' 
						placeholder={t('inputPlaceholder')}
						onSelectItem={handlerItemSelected} 
						stops={stops} 
						initValue={departure} 
						disableUnderline 
					/>
				</div>

				<div className={classes.inputs}>
					<SearchLabel  
						label={t('to')} 
						htmlFor='destination' 
						iconName='map-marker-alt'
					/>
					<SearchInput 
						field='destination' 
						id='destination' 
						placeholder={t('inputPlaceholder')}
						onSelectItem={handlerItemSelected} 
						stops={stops} 
						initValue={destination} 
						disableUnderline 
						err={errorCompare(trip, t('errorMessageSameStops'))} 
					/>
				</div>

				<div className={`${classes.inputs} ${classes.inputDate}`}>
					<SearchLabel  
						label={t('departure')} 
						htmlFor='departureDate' 
						iconName='calendar-alt' 
					/>
					<SearchPicker 
						id='departureDate' 
						field='departureDate' 
						placeholder={t('datePlaceholder')}
						onSelectItem={handlerItemSelected} 
						initValue={departureDate} 
						compareValue={destination} 
					/>
				</div>

				<div className={`${classes.inputs} ${classes.inputDate}`}>
					<SearchLabel  
						label={t('return')} 
						htmlFor='returnDate' 
						iconName='calendar-alt'
						optional={true}
					/>
					<SearchPicker 
						id='returnDate' 
						field='returnDate' 
						placeholder={t('datePlaceholder')}
						onSelectItem={handlerItemSelected}
						minDate={departureDate} 
						initValue={returnDate} 
					/>
				</div>
				<div className={classes.inputs}>
					<SearchSelectPassenger 
						customClasses={{ selectorContainer: classes.selectorContainer }}
						maxValue={7} 
						onChange={handlerPassengersChange} 
					/>
				</div>
			</div>
			<SearchButton id='searchButton' disabled={!formValid} type='submit'>
				{ t('searchTickets') }
			</SearchButton>
		</SearchContainer>
	);
}