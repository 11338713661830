import React from 'react';
import { useTranslation } from 'react-i18next';

import Image from 'components/shared/image';

import urls from 'shared/urls';
import moment from 'moment';

import { useApplication } from 'hooks/application';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import './translations';

export default ({ ticket, idTransportationCompany, idEconomicGroup, customClasses }) => {
    useStyles(classes);
    const { t } = useTranslation('promotion');
    const { country: { url } } = useApplication();
    const date = moment().add(1,'days').format('DD-MM-YYYY');
    const { 
        originPad, idOriginPad, destinationPad, idDestinationPad, bannerLink,
        isBanner, image, altImage 
    } = ticket;

    const getPath = () =>{
        const link = `${urls.getDomain(url)}/${t('services')}/${t('search')}/${urls.toSEOFriendly(originPad)}/${urls.toSEOFriendly(destinationPad)}/${idOriginPad}/${idDestinationPad}/${date}/_`;
        
        return idEconomicGroup
            ? `${link}?idgrupoeconomico=${idEconomicGroup}`
            : `${link}?idproveedor=${idTransportationCompany}`;
    }

    const getLink = () => window.location = (isBanner && bannerLink) ? bannerLink : getPath();

    return (
        <a
            className={customClasses && customClasses.link} 
            onClick={getLink}  
            alt={`${t('from')} ${originPad} ${t('to')} ${destinationPad}`}
            data-testid='promo'
        >
        {
            isBanner
                ? <div className={customClasses ? `${classes.banner} ${customClasses.banner}` : classes.banner}>
                    <Image 
                        image={image} 
                        alt={altImage} 
                        title={altImage} 
                        customClasses={classes.image} 
                    />
                  </div>
                : <div className={customClasses ? `${classes.promotion} ${customClasses.promotion}` : classes.promotion }>
                    <Image image={image} alt={altImage} title={altImage} customClasses={classes.image} />
                    <div className={classes.header}>
                        <span className={classes.span}>
                            { t('from') } {originPad}
                        </span>
                        <span className={classes.span}>
                            { t('to') } {destinationPad}
                        </span>
                    </div>
                  </div>
        }
        </a>
    )
}
