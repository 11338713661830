import moment from 'moment';

const validateForm = trip => {
    const { country, destination } = trip;

    return !country || !destination
        ? false
        : true;
}

const generateUrl = (trip, servicesText, searchText) => {
    const { departureId, destinationId, date } = trip;
    const datePath = date ? `${moment(date).format('DD-MM-YYYY')}/_` : '';

    return `/${servicesText}/${searchText}/${departureId}/${destinationId}/${datePath}`;
}

export { validateForm, generateUrl }