import mapSeoTextBanners from "./mapSeoTextBanners";
import mapDealBanner from "./mapDealsBanner";
import mapMetaData from "./mapMetaData";

const mapHomeCabs = data =>{
    if(!data?.homeRemises || data.homeRemises.length == 0) return null;
    const { metadata,cajasSeo,bannerPromocion } = data.homeRemises[0];

    return{
        dealsBanner:mapDealBanner(bannerPromocion),
        seoTextBox:mapSeoTextBanners(cajasSeo),
        metadata:mapMetaData(metadata)
    };
};

export default mapHomeCabs;