import React, { useState, useEffect } from 'react';

import CustomHead from 'components/shared/customHead';
import SearchBox from 'components/ferries/searchBox';
import DealsBanners from 'components/shared/dealsBanners';
import SeoTextBanners from 'components/shared/seoTextBanners';
import SeoBanners from 'components/shared/seoBanners';
import NoSSR from 'components/shared/noSSR';

import useContent from 'hooks/content';
import { useApplication } from 'hooks/application'
import useRedis from 'hooks/cms/redis';
import mapHomeFerries from 'hooks/cms/mappers/mapHomeFerries';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';

export default () => {
  useStyles(classes, globalStyles);
  const { getFerriesMetadata } = useContent();

  const [data, setData] = useState({});
  const { idPv } = useApplication();
  const { getCache } = useRedis();

  const enableStrapi = process.env.FT_ENABLE_STRAPI_HOMEFERRIES;

  useEffect(() => {
    const getData = async() => {
      let metadata = await getFerriesMetadata();
      if(!metadata.UrlCanonica){
        metadata.UrlCanonica=window.location.href;
      }   
      setData({metadata});
    };

    const getContent = async() => {
      const cmsContent = await getCache({key:'homeFerriesQuery',idPv:idPv});
      const { metadata,dealsBanner,seoTextBox,seoBanner } = mapHomeFerries(cmsContent);
      setData({ metadata,dealsBanner,seoTextBox,seoBanner });
    };

    if(enableStrapi) {
      getContent();
    } else {
      getData();
    }
  }, []);

  return (
    <div className={classes.homeContainer}>
      <CustomHead metadata={data.metadata} />
      <div className={classes.search}>
        <div className={classes.searchbox}>
          <SearchBox />
        </div>
        <div className={classes.dealsContainer}>
          <DealsBanners dealsBanner={data.dealsBanner} endpoint='ApiFluviales/ObtenerPromocionesHome' feature={process.env.FT_ENABLE_STRAPI_HOMEFERRIES}/>
        </div>
      </div>
      <NoSSR>
        <div className={globalStyles.plr5}>
          <div className={`${globalStyles.flex} ${globalStyles.mtb10}`}>
            <SeoBanners seoBanner={data.seoBanner} endpoint='ApiFluviales/ObtenerBannersSEOHome' feature={process.env.FT_ENABLE_STRAPI_HOMEFERRIES}/>
          </div>
          <div className={globalStyles.flex}>
            <SeoTextBanners seoTextBox={data.seoTextBox} endpoint='ApiFluviales/ObtenerTextosSEOHome' feature={process.env.FT_ENABLE_STRAPI_HOMEFERRIES}/>
          </div>
        </div>
      </NoSSR>
    </div>
  );
}