import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'components/shared/icon';
import Image from 'components/shared/image';
import Modal from 'components/shared/modal';
import Map from 'components/shared/map';
import Button from 'components/shared/button';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import './translations';

export default ({ pv }) => {
    useStyles(classes, globalStyles);
    const { t } = useTranslation('pv');
    const [showModal, setShowModal] = useState(false);

    const title = pv.alias ? `${pv.alias}|${pv.address}` : pv.address;

    const handleModal = () => setShowModal(!showModal);

    return (
        <div className={classes.container}>
            <div className={classes.text}>
                {
                    pv.highlighted && 
                        <div className={classes.image}>
                            <Image image={pv.image} alt={title} />
                        </div>
                }
                <div className={`${classes.flex1} ${globalStyles.flexColumn}`}>
                    <span className={`${globalStyles.fs18} ${globalStyles.fwBold}`}> { title } </span>
                    <span className={globalStyles.fs13}> { pv.schedule } </span>
                </div>
            </div>
            <div className={`${globalStyles.mb15} ${globalStyles.flexCenter}`}>
                <Icon 
                    name='map-marker-alt' 
                    classes={pv.latitude &&  pv.longitude  ? classes.icon : `${classes.icon} ${classes.inactive}`} 
                />
                <Icon 
                    name='credit-card' 
                    classes={(pv.hasDebitCard && pv.hasCreditCard || pv.sellsWithCreditCardWeb || pv.sellsWithCreditCard ||  pv.hasCreditCard) ? classes.icon : `${classes.icon} ${classes.inactive}`} 
                />
                <Icon 
                    name='bus' 
                    classes={classes.icon} 
                />
                <Icon 
                    name='plane' 
                    classes={pv.hasFlightsActivated ? classes.icon : `${classes.icon} ${classes.inactive}`} 
                />
            </div>
            <div className={`${classes.flex1} ${globalStyles.flexCenter}`}>
            {
                pv.latitude && pv.longitude &&
                    <Button onClick={handleModal}> { t('seeMore') } </Button>
            }
            </div>
            <>
                {
                    showModal &&
                        <Modal data-testid='modal' customClasses={{ modal: classes.modal }} hideHeader={true}>
                            <div className={`${globalStyles.flexColumn} ${globalStyles.p20}`}>
                                <span className={globalStyles.fwBold}> { pv.address } </span>
                                <span> { pv.schedule } </span>
                            </div>
                            <div className={globalStyles.flex}>
                                <Map lat={pv.latitude} lng={pv.longitude} />
                            </div>
                            <div className={`${globalStyles.mtb15} ${globalStyles.flexJustifyCenter}`}>
                                <Button onClick={handleModal}>
                                    { t('accept') }
                                </Button>
                            </div>
                        </Modal>
                }
            </>
        </div>
    )
}