import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { SearchButton, SearchContainer, SearchSelect, SearchLabel } from 'components/shared/searchbox';
import { useCyT } from 'hooks/c&t';
import NewSearchSelect from '../../shared/searchbox/newSearchSelect';

import { validateForm } from 'shared/c&t/searchbox';
import groupBy from 'shared/groupBy';
import breakpoints from 'shared/breakpoints';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import './translations';

export default ({ getRedirectionUrl, title, placeHolder }) => {
	useStyles(classes, globalStyles);
	const history = useHistory();
	const { t } = useTranslation('transfersSearchbox');
	const { trip, setTrip, subtitle, countries, destinations: receivedDestinations } = useCyT();
	const isMobile = useMediaQuery({ query: `(max-width:${breakpoints.md})` });

	const [showSecondSelect, setShowSecondSelect] = useState(false);
	const [formValid, setFormValid] = useState(false);
	const [secondSelectValue, setSecondSelectValue] = useState(null);
	const [destinations, setDestinations] = useState(receivedDestinations);

	useEffect(() => {
		const valid = validateForm(trip);
		setFormValid(valid);
	}, [trip]);

	const handleSearch = async event => {
		event.preventDefault();
		const destination = receivedDestinations.find(destination => destination['id'] === secondSelectValue);
		const url = await getRedirectionUrl({ circuitId: 0, destinationId: destination.id });
		history.push(url);
	};

	const handleCountrySelectChange = id => {
		const filteredDestinations = groupBy('groupBy')(receivedDestinations.filter(destination => id === destination.countryId));
		setDestinations(filteredDestinations);
		setShowSecondSelect(true);
		setTrip({
			...trip,
			country: id
		});
	}

	const handleSelectChange = id => {
		const destination = receivedDestinations.find(destination => destination['id'] === id);
		setSecondSelectValue(id);
		setTrip({
			...trip,
			destination: destination
		});
	}

	return (
		<SearchContainer
			onSubmit={handleSearch}
			title={title}
			gridTemplateRows={'repeat(4, 1fr)'}
			gap='10px'
		>
			{
				isMobile &&
				<div className={globalStyles.flexColumn}>
					<SearchLabel label={subtitle} htmlFor='transfers' />
				</div>
			}
			<div className={classes.container}>
				<div className={globalStyles.flexColumn}>
					<NewSearchSelect
						placeHolder={t('selectCountry')}
						data={countries}
						onChange={handleCountrySelectChange}
					/>
				</div>
				{ 
					showSecondSelect &&
					<div className={globalStyles.flexColumn}>
						<NewSearchSelect
							placeHolder={placeHolder}
							data={destinations}
							onChange={handleSelectChange}
							hasGroup={true}
						/>
					</div>
				}
			</div>
			<div className={classes.searchButton}>
				<SearchButton id='searchButton' disabled={!formValid} type='submit'>
					{t('searchTickets')}
				</SearchButton>
			</div>

		</SearchContainer>
	);
}