export default {
    title:'Viagem especial',
    requiredFields: 'Campos obrigatórios',
    numberOfPassengers: 'Número de passageiros',
    origin: 'Origem',
    destiny: 'Destino',
    departureFromTheOrigin: 'Partida da origem',
    departureFromTheDestination: 'partida do estino',
    onceAtDestination: 'Uma vez no destino',
    destinationMovements: 'Movimentos no destino?',
    destinationMovementsDetail: 'Digite a descrição dos movimentos que eles farão quando chegarem ao destino',
    carRemainWithTheGroup: 'O carro deve permanecer com o grupo?',
    yes: 'Sim',
    no: 'Não',
    select: 'Selecionar',
    carCategory: 'Categoria de carro',
    catetingOnBoard: 'Catering a bordo',
    provideFoodToDrivers: 'Eles abrigam e fornecem comida para os motoristas?',
    passengerCharacteristics: 'Características do passageiro',
    institutionTitle: 'Instituição para a qual o serviço é solicitado',
    institutionName: 'Nome da instituição',
    contactInformation: 'Dados para contato',
    nameAndSurname: 'Nome e sobrenome',
    phoneContact: 'Telefone de contato',
    mailContact: 'Mail de contato',
    observations: 'Observações',
    howDidYouMeetUs: 'Como você nos conheceu?',
    sendRequest: 'Enviar solicitação',
    accept: 'Aceitar',
    escolaresJovenes: 'jovem / alunos',
    adults: 'Adultos',
    elderly: 'Anciãos',
    families: 'Famílias',
    other: 'Outros',
    coffeeJuice: 'Café / Suco',
    foodOnBoard: 'Comida a bordo',
    restaurantMeals: 'Refeições no restaurante',
    noCatering: 'Sem catering',
    bySearchEngines: 'Por mecanismos de busca na Internet (Google, Yahoo, Bing)',
    socialNetworks: 'Redes sociais',
    onPlataforma10Com: 'Em plataforma10.com',
    onPlataforma10Net: 'Em plataforma10.net',
    trainingForSellers: 'Para treinamento para vendedores',
    atPointSale: 'Em um ponto de venda da plataforma 10',
    byMailWithNews: 'Por receber um email com a notificação',
    byReferred: 'Por conocidos',
    street: 'Rua',
    city: 'Cidade',
    province: 'Província',
    country: 'País',
}