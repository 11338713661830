import React, { useEffect, useState } from 'react';

import Slider from 'components/shared/slider';
import Deal from 'components/shared/deals/deal';
import Content from 'components/shared/content';
import SelectFields from 'components/c&t/detail/selectFields';
import Icon from 'components/shared/icon';
import Image from 'components/shared/image';
import Loading from 'components/shared/loading';

import { CyTProvider, getCircuits } from 'hooks/c&t';
import urls from 'shared/urls';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';

const Detail = ({ match: { params: { destinationId, destinationName, circuitName } } }) => {
  useStyles(classes, globalStyles);
  const { circuits } = getCircuits(destinationId, destinationName);
  const [circuit, setCircuit] = useState(null);

  useEffect(() => {
    if (circuits.length) {
      const circuit = circuits.find(circuit => urls.toSEOFriendly(circuit.name) === circuitName.toLowerCase());
      setCircuit(circuit);
    }
  }, [circuits.length]);

  return (
    circuit 
      ? <div className={classes.container}>
          <div className={`${classes.title} ${globalStyles.flexJustifyCenter}`}>
            <span className={globalStyles.fs36}> { circuit.name } </span>
          </div>
          <div className={classes.infoContainer}>
            <div className={classes.imageContainer}>
              {
                circuit.aditionalImages.length > 0
                  ? <>
                      <Slider 
                        slides={circuit.aditionalImages} 
                        customClasses={{ container: classes.sliderContainer }}
                      >
                        {
                          circuit.aditionalImages.map((img, index) => {
                            const { image: { alt, title, image } } = img;

                            return (
                              <Image 
                                image={image} 
                                alt={alt} 
                                title={title} 
                                customClasses={classes.slide} 
                                key={index}
                              />
                            )
                          })
                        }
                      </Slider>
                      <Content 
                        params={{ showPrice: true, price: circuit.basePrice }} 
                        customClasses={{ 
                          container: classes.contentContainer, 
                          priceBadge: classes.priceBadge 
                        }} 
                      />
                    </>
                  : <Deal
                      alt={circuit.name}
                      image={urls.getCloudFrontPath(`circuitos/${circuit.image}`)}
                      customClasses={{ link: classes.link }}
                    >
                      <Content 
                        params={{ showPrice: true, price: circuit.basePrice }} 
                        customClasses={{ priceBadge: classes.priceBadge }} 
                      />
                    </Deal>
              }
            </div>
            <div>
                <span className={classes.days}>
                    <Icon name={'calendar-alt'} classes={classes.icon} />
                    { circuit.days }
                </span>
                <div>
                  <p dangerouslySetInnerHTML={{ __html: circuit.description }} />
                </div>
            </div>
          </div>
          <SelectFields circuit={circuit} />
        </div>
      : <Loading />
  );
}

export default ({ location, match, classes }) => {
  return (
    <CyTProvider>
      <Detail location={location} match={match} classes={classes} />
    </CyTProvider>
  )
}