// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._613e1b0e84fad453e5afeae2faef6da1-scss{display:flex;flex-direction:column;background:var(--grey-light);margin:20px 0;padding:10px 15px;border-radius:10px;box-shadow:1px 2px 8px 1px}\n", ""]);
// Exports
exports.locals = {
	"container": "_613e1b0e84fad453e5afeae2faef6da1-scss"
};
module.exports = exports;
