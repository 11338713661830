export default {
    name: "Name",
    email: 'example@example.com',
    chooseRating: 'Your score',
    bad: 'bad',
    regular: 'Regular',
    good: 'Good',
    veryGood: 'Very good',
    excellent: 'Excellent',
    yourComment: 'Your comment',
    send: 'Send',
    thanks: 'Thanks for your opinion!',
    commentSentMessage: 'Your comment will be published shortly',
}