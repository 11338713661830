import React, { useState, useEffect } from 'react';

import CustomHead from 'components/shared/customHead';
import SearchBox from 'components/flights/searchBox';
import DealsBanners from 'components/shared/dealsBanners';
import SeoTextBanners from 'components/shared/seoTextBanners';
import SeoBanners from 'components/shared/seoBanners';
import NoSSR from 'components/shared/noSSR';

import useContent from 'hooks/content';
import { useApplication } from 'hooks/application'
import useRedis from 'hooks/cms/redis';
import mapHomeFlights from 'hooks/cms/mappers/mapHomeFlights';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';

import { useTranslation } from 'react-i18next';
import './translations';

export default () => {
  useStyles(classes, globalStyles);
  const { getFlightsMetadata } = useContent();
  const [data, setData] = useState({});
  const { t } = useTranslation('flightsHome');
  const { idPv, features } = useApplication();
  const { getCache } = useRedis();

  const enableStrapi = process.env.FT_ENABLE_STRAPI_HOMEFLIGHTS;

  useEffect(() => {
    const getData = async () => {
      let metadata = await getFlightsMetadata();

      if(!metadata.UrlCanonica){
        metadata.UrlCanonica=window.location.href;
      }   

      setData({metadata});
    };

    const getContent = async()=>{
      const cmsContent = await getCache({key:'homeFlightsQuery',idPv:idPv});
      const { metadata,dealsBanner,seoTextBox,seoBanner } = mapHomeFlights(cmsContent);
      setData({ metadata,dealsBanner,seoTextBox,seoBanner });
    }

    if(enableStrapi){
      getContent();
    }else{
      getData();
    }
  }, []);

  

  return (
    <div className={classes.homeContainer}>
      <CustomHead metadata={data.metadata} />
      <div className={classes.search}>
        <div className={classes.searchbox}>
          {
            features.enableFlight ?
              <SearchBox /> :
              <div className={classes.message}> {t('message')}</div>
          }
        </div>
        <div className={classes.dealsContainer}>
          <DealsBanners dealsBanner={data.dealsBanner} endpoint='ApiVuelos/ObtenerPromocionesHome' feature={process.env.FT_ENABLE_STRAPI_HOMEFLIGHTS}/>
        </div>
      </div>
      <NoSSR>
        <div className={globalStyles.plr5}>
          <div className={`${globalStyles.flex} ${globalStyles.mtb10}`}>
            <SeoBanners seoBanner={data.seoBanner} endpoint='ApiVuelos/ObtenerBannersSEOHome' feature={process.env.FT_ENABLE_STRAPI_HOMEFLIGHTS}/>
          </div>
          <div className={globalStyles.flex}>
            <SeoTextBanners seoTextBox={data.seoTextBox} endpoint='ApiVuelos/ObtenerTextosSEOHome' feature={process.env.FT_ENABLE_STRAPI_HOMEFLIGHTS}/>
          </div>
        </div>
      </NoSSR>
    </div>
  );
}