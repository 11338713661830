// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._8b0234c146ea4f0ee3d5291db4292ed6-scss{position:absolute;bottom:0;text-align:left;box-sizing:border-box}.a8799fa580ff16b235193ec67dd9270a-scss{text-shadow:2px 2px 0 #000}._496957e0504c59815aec11fc0ca3fd79-scss{margin:10px 0 15px;font-size:13px;text-shadow:2px 2px 0 #000}.ab3f506d7fe2bcd879878ec90cf946f4-scss{grid-gap:10px}\n", ""]);
// Exports
exports.locals = {
	"content": "_8b0234c146ea4f0ee3d5291db4292ed6-scss",
	"title": "a8799fa580ff16b235193ec67dd9270a-scss",
	"subtitle": "_496957e0504c59815aec11fc0ca3fd79-scss",
	"bannerContainer": "ab3f506d7fe2bcd879878ec90cf946f4-scss"
};
module.exports = exports;
