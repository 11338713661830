import React, { useEffect, useState } from 'react';

import Loading from 'components/shared/loading';
import Deals from 'components/shared/deals';
import Content from 'components/shared/content';

import { useApplication } from 'hooks/application';
import useAxios from 'hooks/axios';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';

export default ({ dealsBanner, endpoint, feature }) => {
    useStyles(classes);
    const axios = useAxios();
    const { culture, country, idPv } = useApplication();

    const [data, setData] = useState();

    const mapData = data => {
        return {
            deals: data.map(deal => {
                return {
                    link: deal.UrlRedireccion,
                    imageAlt: deal.AltImagen,
                    image: deal.Imagen,
                    showPrice: deal.MostrarPrecioDesde,
                    price: deal.Precio,
                    subtitle: deal.Subtitulo,
                    informativeText: deal.TextoInformativo,
                    title: deal.Titulo,
                    showButton: true
                }
            })
        }
    };

    useEffect(() => {
        const getData = async () => {
            const res = await axios.get(`${endpoint}?idPv=${idPv}&lang=${culture.iso_code}&pais=${country.url}`)
            
            if (res && res.data) {
                const mappedData = mapData(res.data);
                setData(mappedData);
            }
        }
        if(!dealsBanner && !process.env.FT_ENABLE_STRAPI_DEALS_BANNER && !feature) getData();
    }, []);

    useEffect(()=>{
        if(dealsBanner && process.env.FT_ENABLE_STRAPI_DEALS_BANNER && feature) setData(dealsBanner);
    },[dealsBanner])

    return (
        data
            ? <Deals customClasses={{ bannerContainer: classes.bannerContainer }} data={data}>
                {
                    data.deals.map((deal, index) => (
                        <Content                     
                            key={index}
                            customClasses={{ 
                                content: classes.content,
                                title: classes.title, 
                                subtitle: classes.subtitle
                            }} 
                            params={{
                                showPrice: deal.showPrice,
                                price: deal.price,
                                subtitle: deal.subtitle,
                                informativeText: deal.informativeText,
                                title: deal.title,
                                showButton: deal.showButton
                            }}
                        />
                    ))
                }
              </Deals> 
            : <Loading />
    )
}
