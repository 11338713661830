import React from 'react'

import Image from 'components/shared/image';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';

export default ({ data }) => {
  useStyles(classes, globalStyles);
  const { name, description, imgOmnibus, altImgOmnibus, imgTopRight, altImgTopRight, imgBottomRight, altImgBottomRight } = data;

  return (
    <section id='information'>
      <div className={classes.root}>
        <div>
          <h2 className={globalStyles.fs30}> { name } </h2>
          <div className={globalStyles.mt20}>
            <Image 
              image={imgOmnibus} 
              alt={altImgOmnibus} 
              title={altImgOmnibus}
              width='220'
              height='127' 
            />
          </div>
          <div dangerouslySetInnerHTML={{ __html: description }} className={classes.description} />
        </div>
        <div>
          <div>
            <Image image={imgTopRight} alt={altImgTopRight} title={altImgTopRight} />
          </div>
          <div className={globalStyles.mt20}>
            <Image image={imgBottomRight} alt={altImgBottomRight} title={altImgBottomRight} />
          </div>
        </div>
      </div>
    </section>
  )
}
