// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._0831e2aa6f8e913bc89c9303cc8e0495-scss{width:100%;height:100%}._64ec5309928902028357513f7e688454-scss{display:grid;min-height:250px}.b408a3ba12da880144851bf35babf02a-scss{display:grid;grid-template-rows:200px 50px}._4d337de97a5e8540858f747d96ebdba4-scss{display:grid;background:var(--destination-title-item-bg-color);padding:5px}.a076c2c309b2e37755f8f9895f5d821e-scss{color:var(--white-light);font-size:14px;font-weight:bold;line-height:20px}@media only screen and (max-width: 768px){._0831e2aa6f8e913bc89c9303cc8e0495-scss{height:auto}}\n", ""]);
// Exports
exports.locals = {
	"image": "_0831e2aa6f8e913bc89c9303cc8e0495-scss",
	"banner": "_64ec5309928902028357513f7e688454-scss",
	"promotion": "b408a3ba12da880144851bf35babf02a-scss",
	"header": "_4d337de97a5e8540858f747d96ebdba4-scss",
	"span": "a076c2c309b2e37755f8f9895f5d821e-scss"
};
module.exports = exports;
