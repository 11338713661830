import moment from 'moment';

const validateForm = trip => {
    const { hotel, departureDate, returnDate, rooms, people } = trip;

    if (!hotel || !departureDate || !rooms || !people) {
        return false;
    }

    if (returnDate && (returnDate < departureDate)) {
        return false;
    }

    if (rooms < 1) {
        return false;
    }

    return true;
}

const getUrl = params => {
    const { searchType, urlParams, trip, lang, t } = params;
    const { departureDate, returnDate: receivedReturnDate, rooms, people } = trip;
    const returnDate = receivedReturnDate ? moment(receivedReturnDate).format('DD-MM-YYYY') : '';

    let qsParams = `${urlParams}&sd=${moment(departureDate).format('DD-MM-YYYY')}&ed=${returnDate}&rm=${rooms}`;
    
    for (let i = 0; i < rooms; i++) {
        const room = (i + 1);
        const adults = people[i].adults;
        const childrens = people[i].childrens;
        const ages = `${people[i].ages[0] || 1},${people[i].ages[1] || 1},${people[i].ages[2] || 1},${people[i].ages[3] || 1},${people[i].ages[4] || 1}`;
        qsParams += `&ad${room}=${adults}&ch${room}=${childrens}&ac${room}=${ages}`;
    }

    return `http://hoteles.plataforma10.com/${t('url')}/${searchType}?af=${t('fa')}&ln=${lang}&cu=${t('pa')}${qsParams}`;
}

const generateUrl = (trip, t, lang) => {
    const { hotel:{ type, typeId, text } } = trip;
    
    const types = {
        C: { searchType: 'Lista', params: `&cin=${typeId}&dn=${text}&ht=` },
        D: { searchType: 'Lista', params: `&ds=${typeId}` },
        H: { searchType: 'Tarifas', params: `&ht=${typeId}` },
    }

    const params = {
        lang, trip, t, searchType: types[type].searchType, urlParams: types[type].params
    }

    return getUrl(params);
}

export { validateForm, generateUrl }