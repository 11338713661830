const mapDealBanner = data =>{
   
    return {
        deals: data.map(deal => {
            const { link,banner,mostrarPrecioDesde,precio,subtitulo,textoInformativo,titulo,mostrarBoton } = deal;

            return{
                link: link,
                imageAlt: banner.imagen[0].alternativeText,
                image: banner.imagen[0].url,
                showPrice: mostrarPrecioDesde,
                price: precio,
                subtitle: subtitulo,
                informativeText: textoInformativo,
                title: titulo,
                showButton: mostrarBoton
            }
        })
    }
}

export default mapDealBanner;