export default {
    searchTickets: 'Search Hotels',
    title: 'Search your hotel now',
    from:'Hotel, city or destination',
    departureDate: 'From',
    returnDate:'To',
    selectRooms:'Select number of rooms',
    url: 'Hoteles',
    fa:'AF-BKQ',
    pa: 'PA'
}