export default {
    searchTickets: 'Buscar Hoteles',
    title: 'Buscá tu hotel ahora',
    from:'Hotel, ciudad o destino',
    departureDate: 'Entrada',
    returnDate:'Salida',
    selectRooms:'Seleccione habitaciones',
    url: 'Hoteles',
    fa:'AF-BKQ',
    pa: 'PA'
}