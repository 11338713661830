import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import SearchBox from 'components/c&t/searchBox';
import FeatureDeal from 'components/shared/c&tBanners/featureDeal';
import Deals from 'components/shared/c&tBanners/deals';
import CustomHead from 'components/shared/customHead';
import SeoTextBanners from 'components/shared/seoTextBanners';
import NoSSR from 'components/shared/noSSR';

import useAxios from 'hooks/axios';
import { CyTProvider, getIndex } from 'hooks/c&t';
import useContent from 'hooks/content';
import { useApplication } from 'hooks/application'
import useRedis from 'hooks/cms/redis';
import mapHomeCircuits from 'hooks/cms/mappers/mapHomeCircuits';

import urls from 'shared/urls';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import './translations';

const Circuits = () => {
  useStyles(classes, globalStyles);
  const axios = useAxios();
  const { t } = useTranslation('circuits');
  const { getDealsCircuit, getFeaturedCircuit } = useContent();

  const [featureDeal, setFeatureDeal] = useState();
  const [deals, setDeals] = useState([]);
  const [data, setData] = useState();

  const { metadata, destinations } = getIndex('ApiCircuitosTraslados/Index');
  const { idPv } = useApplication();
  const { getCache } = useRedis();

  const enableStrapi = process.env.FT_ENABLE_STRAPI_HOME_CIRCUITS;

  const getSelectedCircuitName = async (circuitId, destinationId) => {
    const res = await axios.get(`ApiCircuitosTraslados/ObtenerDetalleCircuito?idCircuito=${circuitId}&idDestino=${destinationId}`)
    if (res.data) return res.data.Nombre;
  }

  const getRedirectionUrl = async params => {
    const { circuitId, destinationId } = params;
    const destination = destinations.find(destination => destination.id === destinationId);

    if (!destination) return '/';

    if (circuitId === 0) 
      return `/${t('circuits')}/${t('search')}/${destinationId}/${urls.toSEOFriendly(destination.name)}`;

    const circuitName = await getSelectedCircuitName(circuitId, destinationId);
    return `/${t('circuits')}/${t('detail')}/${destinationId}/${circuitId}/${urls.toSEOFriendly(destination.name)}/${urls.toSEOFriendly(circuitName)}`;
  }

  useEffect(() => {
    const getDeals = async () => {
      if(!metadata.UrlCanonica){
        metadata.UrlCanonica=window.location.href;
        }

        try{
          setData({metadata, ...rest});
        }catch(ex){
          console.log(ex);
        }
      
      const deals = await getDealsCircuit();
      for (let i in deals) {
        deals[i].link = await getRedirectionUrl(deals[i])
      }
      setDeals(deals);
    }

    const getContent = async () => {
      const cms = await getCache({key:'homeCircuitsQuery',idPv:idPv});
      const { deals,featureDeal,metadata,seoTextBox } = mapHomeCircuits(cms);

      featureDeal.link = getRedirectionUrl(featureDeal);
      deals.forEach(deal => deal.link = getRedirectionUrl(deal));

      setData({ deals,featureDeal,metadata,seoTextBox });
    }

    if (destinations.length > 0 && enableStrapi) {
      getContent();
    } else if(destinations.length > 0) {
      getDeals();
    }
  }, [destinations.length])

  useEffect(() => {
    const getFeatureDeal = async () => {
      const featureDeal = await getFeaturedCircuit();
      featureDeal.link = await getRedirectionUrl(featureDeal);
      setFeatureDeal(featureDeal);
    }

    if (destinations.length > 0 && !enableStrapi) getFeatureDeal();
  }, [destinations.length])

  return (
    data
        ? <div className={classes.homeContainer}>
            <CustomHead metadata={data.metadata} />
            <div className={classes.search}>
              <div className={classes.searchbox}>
                <SearchBox 
                  getRedirectionUrl={getRedirectionUrl} 
                  title={t('searchboxTitle')} 
                  placeHolder={t('placeHolder')} 
                />
                </div>
              <div className={classes.dealsContainer}>
                <FeatureDeal featureDeal={data.featureDeal} />
              </div>
            </div>
            <NoSSR>
              <div className={classes.sectionsContainer}>
                <div className={`${classes.transfers} ${globalStyles.flex}`}>
                  <Deals deals={data.deals} />
                </div>
                <SeoTextBanners seoTextBox={data.seoTextBox} endpoint='ApiCircuitosTraslados/ObtenerTextosSEOCircuitos' feature={process.env.FT_ENABLE_STRAPI_HOME_CIRCUITS}/>
              </div>
            </NoSSR>
          </div>
        : <div className={classes.homeContainer}>
            <CustomHead metadata={metadata} />
            <div className={classes.search}>
              <div className={classes.searchbox}>
                <SearchBox 
                  getRedirectionUrl={getRedirectionUrl} 
                  title={t('searchboxTitle')} 
                  placeHolder={t('placeHolder')} 
                />
                </div>
              <div className={classes.dealsContainer}>
                <FeatureDeal featureDeal={featureDeal} />
              </div>
            </div>
            <NoSSR>
              <div className={classes.sectionsContainer}>
                <div className={`${classes.transfers} ${globalStyles.flex}`}>
                  <Deals deals={deals} />
                </div>
                <SeoTextBanners seoTextBox={null} endpoint='ApiCircuitosTraslados/ObtenerTextosSEOCircuitos' feature={process.env.FT_ENABLE_STRAPI_HOME_CIRCUITS}/>
              </div>
            </NoSSR>
          </div>
  );
}

export default (() => (
  <CyTProvider>
    <Circuits />
  </CyTProvider>
))