const response = data => ({
    title: data.Titulo,
    detail: data.Detalle,
    urlRedirect: data.UrlRedireccion,
})

const modelEmail = data => ({
    ...data,
    DomicilioDatosParaContacto: `${data.CalleDatosParaContacto}, ${data.CuidadDatosParaContacto}, ${data.ProvinciaDatosParaContacto}, ${data.PaisDatosParaContacto}`,
    DomicilioInstitucionParaLaQuePideServicio: `${data.CalleInstitucionParaLaQuePideServicio}, ${data.CuidadInstitucionParaLaQuePideServicio}, ${data.ProvinciaInstitucionParaLaQuePideServicio}, ${data.PaisInstitucionParaLaQuePideServicio}`
})

export default {
    response,
    modelEmail
}