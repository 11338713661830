import React from 'react';

import GoogleMapReact from 'google-map-react';

import Icon from 'components/shared/icon';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';

export default ({ lat, lng }) => {
    useStyles(classes);
    const center = {
        lat: lat,
        lng: lng
      },
      zoom = 16;

    const AnyReactComponent = () => 
        <div className={classes.iconContainer}>
            <Icon name='map-marker-alt' classes={classes.icon} />
        </div>;

    return (
        <div className={classes.container}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.GOOGLE_KEY, }}
                defaultCenter={center}
                defaultZoom={zoom}
            >
                <AnyReactComponent
                    lat={lat}
                    lng={lng}
                />
            </GoogleMapReact>
        </div>
    )
}
