export default {
    product: 'navios',
    searchTicketsSeo: 'procurar-passagens',
    searchTickets: 'Procurar Passagens',
    searchTicketsNow: 'Compre seu bilhete de ferry',
    search:'Procurar',
    from:'Origem',
    to:'Destino',
    departure: 'Ida',
    return:'Volta',
    errorMessageSameStops: 'Origem e Destino não podem ser iguais',
    inputPlaceholder: 'Entre cidade',
    datePlaceholder: 'Selecione data'
}