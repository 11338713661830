import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

// Component
import IncrementSelector from 'components/shared/IncrementSelector';
import { SearchLabel } from 'components/shared/searchbox';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import './translations';

const Room = ({ onChange, maxValue }) => {
    useStyles(classes, globalStyles);
    const { t } = useTranslation('searchSelectRooms');
    const [values, setValues] = useState({ adults: 2, childrens: 0, ages: [], total: 2});
    
    const handlerAdults = value => handlerChange({ adults: value });
    
    const handlerChange = newValues => {
        const room = { ...values, ...newValues };
        room.total = room.adults + room.childrens;

        setValues(room);
        if (onChange) onChange(room);
    }

    const handleAgesClick = (value, index) => {
        values.ages[index] = value;
        handlerChange({ ages: values.ages });
    }

    const isDisabled = values.total >= maxValue;

    const handlePlusClick = value => {
        values.ages.push(1);        
        handlerChange({ childrens: value, ages: values.ages });
    }
    
    const handleMinusClick = value => {
        values.ages.pop();
        handlerChange({ childrens: value, ages: values.ages });
    }


    const childrenAgeSelector = index => (
        <div key={index} className={classes.selector}>
            <div className={globalStyles.flexCenter}>
                <SearchLabel 
                    customClasses={{ labels: classes.childrenAgeLabel }}
                    label={`${t('childrenAge')} ${index + 1}`} 
                    htmlFor='childrenAge' 
                />
            </div>
            <div className={classes.middleDiv}>
                <IncrementSelector 
                    minValue={1} 
                    maxValue={17} 
                    value={1}
                    onPlusClick={value => handleAgesClick(value, index)} 
                    onMinusClick={value => handleAgesClick(value, index)}
                />
            </div>
        </div>
    )

    return (
        <div className={classes.passengersContainer}>
            <div className={classes.roomContainer}>
                <SearchLabel customClasses={{ labels: classes.roomsLabel }} uppercase label={t('room')} htmlFor='room' />
            </div>
            <div className={globalStyles.flex}>
                <div className={classes.selector}>
                    <div className={globalStyles.flexCenter}>
                        <SearchLabel customClasses={{labels: classes.label}} label={t('adults')} htmlFor='adults' />
                    </div>
                    <div className={classes.middleDiv}>
                        <IncrementSelector 
                            maxValue={6} 
                            minValue={1} 
                            value={values.adults} 
                            disabled={isDisabled}
                            onChange={handlerAdults} 
                        />
                    </div>
                </div>
                
                <div className={classes.selector}>
                    <div className={globalStyles.flexCenter}>
                        <SearchLabel customClasses={{ labels: classes.label }} label={t('children')} htmlFor='children' />
                    </div>
                    <div className={classes.middleDiv}>
                        <IncrementSelector 
                            maxValue={5}
                            disabled={isDisabled} 
                            value={values.childrens}
                            onPlusClick={value => handlePlusClick(value)} 
                            onMinusClick={value => handleMinusClick(value)}
                        />
                    </div>
                </div>
                {
                    values.ages.map((value,index) => childrenAgeSelector(index))
                }
            </div>
        </div>
    )
}

Room.defaultProps = {
    maxValue: 1,
    onChange: () => { },
}

export default Room;