

const mapDeal = data => {
    if(!data) return null;

    const { titulo,precio,idCircuito,idDestino,imagen } = data;

    return {
        destinationId: idDestino,
        circuitId: idCircuito,
        title: titulo,
        price: precio,
        showPrice: true,
        image: {
            url: imagen.url,
            alt: imagen.alternativeText
        }
    }
}

 export default mapDeal;