import mapSeoTextBanners from "./mapSeoTextBanners";
import mapDealBanner from "./mapDealsBanner";
import mapMetaData from "./mapMetaData";
import mapSeoBanners from "./mapSeoBanners";


const mapHomeFerries = data =>{
    if(!data?.homeFerrises || data.homeFerrises.length == 0) return null;
    const { metadata,cajasSeo,bannerPromocion,bannerSeo } = data.homeFerrises[0];
    return{
        dealsBanner:mapDealBanner(bannerPromocion),
        seoTextBox:mapSeoTextBanners(cajasSeo),
        metadata:mapMetaData(metadata),
        seoBanner:mapSeoBanners(bannerSeo)
    };
};

export default mapHomeFerries;