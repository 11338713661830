import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import CustomHead from 'components/shared/customHead';
import Frame from 'components/shared/frame';
import Loading from 'components/shared/loading';
import Tabs from 'components/shared/tabs';
import Pvs from 'components/howToBuy/pvs';

import { useApplication } from 'hooks/application';
import useContent from 'hooks/content';

import { getTabs } from 'shared/howToBuy';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import './translations';

export default () => {
    useStyles(classes, globalStyles);
    const { country } = useApplication();
    const { t } = useTranslation('personalPurchase');
    const { getHowToBuyPersonalPurchase } = useContent();
    const { id, city } = useParams();

    const [data, setData] = useState(null);
    const [showLoading, setShowLoading] = useState(true);

    const types = { 
        personal:{ active: true, url:'' }, 
        electronic:{ active: false, url: `/${t('electronicPurchaseUrl')}` }, 
        mobile:{ active:false, url: `/${t('mobilePurchaseUrl')}` } 
    };
    
    useEffect(() => {
        const getData = async() => {
            const data = await getHowToBuyPersonalPurchase(id, city);
            setData(data);
            setShowLoading(false);
        };

        getData();
    }, [id, city]);

    return (
        data 
            ? <div className={globalStyles.flexJustifyCenter}>
                <CustomHead metadata={data.metadata} />
                <Frame title={t('title')}>
                    <div className={`${globalStyles.flex} ${globalStyles.ptb10}`}>
                        <Tabs tabs={getTabs(country, t, types)} />
                        <div className={`${classes.infoContainer} ${globalStyles.flexColumn}`}>
                            <div className={`${classes.titleContainer} ${globalStyles.flexAlignCenter}`}>
                                <p className={globalStyles.fwBold}>
                                    { t('information') }
                                </p> 
                            </div>
                            {
                                !showLoading
                                    ? <Pvs data={data} onChange={() => setShowLoading(true)} />
                                    : <Loading />
                            }
                        </div>
                    </div>
                </Frame>
              </div>
            : <Loading />
    )
}