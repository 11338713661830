import React, { useState,useEffect } from 'react';

import { Helmet } from 'react-helmet';
import CustomHead from 'components/shared/customHead';
import SearchBox from 'components/cabs/searchBox';
import DealsBanners from 'components/shared/dealsBanners';
import SeoTextBanners from 'components/shared/seoTextBanners';
import NoSSR from 'components/shared/noSSR';

import useContent from 'hooks/content';
import mapHomeCabs from 'hooks/cms/mappers/mapHomeCabs';
import useRedis from 'hooks/cms/redis';
import { useApplication } from 'hooks/application';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';

export default () => {
    useStyles(classes, globalStyles);
    const { getCabsMetadata } = useContent();
    const [data, setData] = useState({});
    const [mapsLoaded, setMapsLoaded] = useState(false);
    const { getCache } = useRedis();
    const { idPv } = useApplication();
    const enableStrapi = process.env.FT_ENABLE_STRAPI_CMS && process.env.FT_ENABLE_STRAPI_HOMECABS;

    useEffect(()=>{
        const getData = async() => {
            const metadata = await getCabsMetadata();
            setData(metadata);
        };
        const getContent = async()=>{
            const cmsContent = await getCache({key:'homeCabsQuery',idPv:idPv});
            const { metadata, dealsBanner,seoTextBox } = mapHomeCabs(cmsContent);
            setData({ metadata,dealsBanner,seoTextBox });
        };
        if(enableStrapi){
            getContent();
        }else{
            getData();
        }
    },[])

    useEffect(() => {
        window.onGoogleMap = () => setMapsLoaded(true);

        return () => {
            delete window.onGoogleMap
        }
    }, [])

    return (
        <div className={classes.homeContainer}>
            {!mapsLoaded?
            <Helmet>
                <script
                    type='text/javascript' 
                    src={`//maps.google.com/maps/api/js?key=${process.env.GOOGLE_KEY}&sensor=false&language=es&&libraries=places&callback=onGoogleMap`}
                />
            </Helmet>
            :<></>
            }
            <CustomHead metadata={data.metadata} />
            <div className={classes.search}>
                <div className={classes.searchbox}>
                    { mapsLoaded && <SearchBox /> }
                </div>
                <div className={classes.dealsContainer}>
                    <DealsBanners dealsBanner={data.dealsBanner} endpoint='ApiRemises/ObtenerPromocionesHome' feature={process.env.FT_ENABLE_STRAPI_HOMECABS}/>
                </div>
            </div>
            <NoSSR>
                <div className={globalStyles.plr5}>
                    <div className={globalStyles.flex}>
                        <SeoTextBanners seoTextBox={data.seoTextBox} endpoint='ApiRemises/ObtenerTextosSEOHome' feature={process.env.FT_ENABLE_STRAPI_HOMECABS}/>
                    </div>
                </div>
            </NoSSR>
        </div>
    )
}
