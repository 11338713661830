// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._245b6810524dd8f10388a33042947658-scss{min-height:250px}._4ebd6a1502f3b2cb172c3b9f88f9149d-scss{position:absolute;bottom:0;left:0;text-align:left}._3a772517e63a16885e541e60824b8447-scss{font-size:26px;text-shadow:2px 2px 0 #000}._948480368c2f06795e39d4daa7886187-scss{background-size:cover;border-radius:10px}._2bff94cdc47f443e59bb4e1ec1363faf-scss{background:var(--black-dark);height:80px;width:80px;font-size:1rem}._537d683b0ca573baecc52991ffb98144-scss{grid-gap:10px;grid-template-columns:repeat(auto-fit, minmax(270px, 1fr)) !important;margin-bottom:10px}\n", ""]);
// Exports
exports.locals = {
	"deal": "_245b6810524dd8f10388a33042947658-scss",
	"content": "_4ebd6a1502f3b2cb172c3b9f88f9149d-scss",
	"title": "_3a772517e63a16885e541e60824b8447-scss",
	"link": "_948480368c2f06795e39d4daa7886187-scss",
	"priceBadge": "_2bff94cdc47f443e59bb4e1ec1363faf-scss",
	"bannerContainer": "_537d683b0ca573baecc52991ffb98144-scss"
};
module.exports = exports;
