import React from 'react';
import { Helmet } from "react-helmet";

const removeAlternates = () => {
    document.querySelectorAll("[rel='alternate']")
        .forEach(e => e.parentNode.removeChild(e))
};

const Alternates = ({name}) => {
    removeAlternates()
    return (
        <Helmet/>
    )
}

export default Alternates