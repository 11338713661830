import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import SearchBox from 'components/ferries/services/searchbox';

import { ServiceProvider, searchServices } from 'hooks/services';

import stops from 'shared/ferries/stops';
import { mapTrip } from 'shared/ferries/common';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';

const Services = () => {
  useStyles(classes, globalStyles);
  const { departurePadId, destinationPadId, departureDate, returnDate, adults, minors, infants } = useParams();
  const { setTrip } = searchServices();

  useEffect(() => {
    const params = { 
      departurePadId, destinationPadId, departureDate, returnDate, adults, minors, infants 
    };

    const getData = async () => {
        const trip = mapTrip(stops, params);
        setTrip(trip);
    }

    getData();
  }, [stops, departurePadId, destinationPadId, departureDate, returnDate, adults, minors, infants]);

  return (
    <div className={classes.container}>
      <div className={classes.leftColumn}>
        <div className={classes.searchbox}>
          <SearchBox stops={stops} hasServices={false}/>
        </div>
      </div>
    </div>
  )
}

export default () => (
  <ServiceProvider>
    <Services />
  </ServiceProvider>
)