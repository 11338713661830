export default {
    product: 'cabs',
    paySeo: 'pay',
    pay: 'Pay',
    title: 'Quote your cab ride',
    quote: 'Quote',
    from:'From',
    to:'To',
    date: 'Date',
    time: 'Time',
    price: 'Price',
    errorMessageSameStops: 'Origin and Destination cannot be the same',
    inputPlaceholder: 'Enter address or place',
    datePlaceholder: 'Select date',
    timePlaceholder: 'Select time',
    noCoverageError: 'No services were found available for the search.',
    invalidDateError: 'The selected time must be after: ',
    title:'Atention',
    comments: 'Comments',
    commentsPlaceholder: 'Here you can enter any comments about the trip, such as your flat number or the apartment',
    passengers: 'Passengers'
}