import React, { useEffect, useState } from 'react';

import Deals from 'components/shared/deals';
import Content from 'components/shared/content';
import Loading from 'components/shared/loading';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';

export default ({ deals }) => {
    useStyles(classes);
    const [data, setData] = useState();

    const customClasses = {
        deal: classes.deal,
        content: classes.content,
        link: classes.link,
        title: classes.title,
        bannerContainer: classes.bannerContainer,
        priceBadge: classes.priceBadge
    }

    useEffect(() => {
        const mapData = () => {
            const data = deals.map(deal => {
                return {
                    link: deal.link,
                    imageAlt: deal.image.alt,
                    image: deal.image.url,
                    showPrice: deal.showPrice,
                    price: deal.price,
                    title: deal.title
                };
            })

            setData({ deals: data });
        }

        if (deals.length > 0) mapData();
    }, [deals.length])

    return (
        data
            ? <Deals customClasses={customClasses} data={data} serverSide={true}>
                {
                    data.deals.map(deal => (
                        <Content
                            key={deal.link}
                            customClasses={customClasses} 
                            params={{
                                showPrice: deal.showPrice,
                                price: deal.price,
                                title: deal.title,
                            }}
                        />
                    ))
                }
              </Deals>
            : <Loading />
    )
};