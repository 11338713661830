import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchLabel, SearchInput, SearchPicker, SearchButton, SearchContainer } from 'components/shared/searchbox';
import SearchSelectRooms from 'components/hotels/searchSelectRooms';
import Icon from 'components/shared/icon';

import { useApplication } from 'hooks/application';
import useAxios from 'hooks/axios';

import { validateForm, generateUrl } from 'shared/hotels/searchbox';
import { groupByToArray } from 'shared/groupBy';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import { mapHotels } from './mapper';
import './translations';

export default () => {
	useStyles(classes, globalStyles);
	const { t } = useTranslation('hotelsSearchbox');
    const { culture, country: { url } } = useApplication();
    const axios = useAxios();

	const [trip, setTrip] = useState({});
	const [ showPassengers, setShowPassengers ] = useState(false);
	const [formValid, setFormValid] = useState(false);
    const [hotels, setHotels] = useState([]);

	const { hotel, destination, departureDate, returnDate, rooms, people, total } = trip;

	const createHotels = groups => {
		return groups.map(group => {
			return {
				title: group[0].typeName,
				suggestions: group
			}
		})
	}

	const getHotels = async hotel => {
		const search = hotel || '';
		const res = await axios.get(`ApiHoteles/Buscar?busqueda=${search}&idioma=${culture.code}&pais=${url.toUpperCase()}`);

		if (res.data) {
			const mappedData = mapHotels(res.data);
			const groupedHotels = groupByToArray('type')(mappedData);
			const hotels = createHotels(groupedHotels);
			
			setHotels(hotels);
		}
	};

	useEffect(() => {
		getHotels(trip.hotel);
		
		if(!rooms && !rooms && !total && !people) 
			setTrip({ ...trip, rooms:1, total:2, people:[{ adults:2, childrens: 0, ages:[] }] });
	}, []);

	useEffect(() => {
		const valid = validateForm(trip);
		setFormValid(valid);
	}, [hotels, trip]);

	const handlerItemSelected = (item, elem) => setTrip({ ...trip, [elem]: item });
	const handlerHotelKeyDown = item => getHotels(item);

	const handlerPassengersChange = values => {
		const { rooms, people, total } = values;
		!people
			? setTrip({ ...trip, rooms, total })
			: setTrip({ 
				...trip, 
				rooms,
				total,
				people
			  });
	}

	const handleSearch = event => {
		event.preventDefault();
		
		const url = generateUrl(trip, t, culture.code);
        window.location = url;
	};

	const roomsClick = () => setShowPassengers(!showPassengers);
	
	return (
		<SearchContainer onSubmit={handleSearch} title={t('title')} gap='10px' >
			<div className={classes.container}>
				<div className={classes.inputs}>
					<SearchLabel uppercase label={t('from')} htmlFor='departure' iconName='map-marker-alt' />
					<SearchInput 
						hasHeaders={true} 
						field='hotel' 
						id='hotel' 
						placeholder={t('from')}
						onKeyDown={handlerHotelKeyDown} 
						onSelectItem={handlerItemSelected} 
						stops={hotels} 
						initValue={hotel} 
						disableUnderline 
					/>
				</div>

				<div className={`${classes.inputs} ${classes.inputDate}`}>
					<SearchLabel uppercase label={t('departureDate')} htmlFor='departureDate' iconName='calendar-alt' />
					<SearchPicker 
						id='departureDate' 
						field='departureDate' 
						placeholder={t('departureDate')}
						onSelectItem={handlerItemSelected} 
						initValue={departureDate} 
						compareValue={destination} 
					/>
				</div>

				<div className={`${classes.inputs} ${classes.inputDate}`}>
					<SearchLabel uppercase label={t('returnDate')} htmlFor='returnDate' iconName='calendar-alt' />
					<SearchPicker 
						id='returnDate' 
						field='returnDate' 
						placeholder={t('returnDate')}
						onSelectItem={handlerItemSelected} 
						minDate={departureDate} 
						initValue={returnDate} 
						optional={true} 
					/>
				</div>

				<div className={`${classes.inputs} ${classes.inputSelectorPassenger}`}>
					<SearchLabel label={t('selectRooms')} htmlFor='selectRooms' uppercase/>
					<div className={`${classes.input} ${classes.roomInput} ${globalStyles.flexAlignCenter}`} onClick={roomsClick}>
						<div className={classes.iconContainer}>
							<Icon classes={classes.icon} name='bed' />
							{ trip.rooms || 1 }
						</div>
						<div className={classes.iconContainer}>
							<Icon classes={classes.icon} name='users' />
							{ trip.total || 2 }
						</div>
					</div>
				</div>
				
				{
					showPassengers &&
						<div className={`${classes.inputs} ${classes.inputSelectorPassenger}`}>
							<SearchSelectRooms
								maxValue={5} 
								onChange={values => handlerPassengersChange(values)} 
								customClasses={{
									root:classes.selectorPassengerContainer, 
									middleDiv:classes.middleDiv, 
									selectorContainer: classes.selectorContainer, 
									selector: classes.selector, 
									text: classes.text
								}} 
							/>
						</div>
				}
			</div>
			
			<SearchButton id='searchButton' disabled={!formValid} type='submit'>
				{t('searchTickets')}
			</SearchButton>
		</SearchContainer>
	);
}