export default {
    name: 'Nome',
    email: 'example@example.com',
    chooseRating: 'Coloque sua pontuação',
    bad: 'Ruim',
    regular: 'Regular',
    good: 'Bom',
    veryGood: 'Muito bom',
    excellent: 'Exelente',
    yourComment: 'Seu comentário',
    send: 'Enviar',
    thanks: 'Obrigado pela sua opinião!',
    commentSentMessage: 'Seu comentário será publicado em breve',
}