import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import Promotion from 'components/landings/featuredContent/promotion';

import breakpoints from 'shared/breakpoints';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import './translations';

export default ({ landing }) => {
    useStyles(classes, globalStyles);
    const { t } = useTranslation('featuredContent');
    const isMobile = useMediaQuery({ query: `(max-width:${breakpoints.md})`});

    const { 
        description: { name, description }, idTransportationCompany, 
        idEconomicGroup, mostSearchedTickets:promotions 
    } = landing;

    const GetShortDescription = () => {
        const parsedText = description
            ? description.replace(/<[^>]+>/gm, '')
            : '';

        return `${parsedText.substr(0, 330)}... `;
    }

    return (
        <div className={classes.root}>
            {
                description &&
                    <div className={classes.informationContainer}>
                        <span className={classes.information} dangerouslySetInnerHTML={{ __html: GetShortDescription() }} />
                        <a href="#information" className={classes.more}> { t('seeMore') } </a>
                    </div>
            }
            {
                promotions.length > 0 &&
                    <>
                        {
                            isMobile &&
                                <div>
                                    <h2 className={globalStyles.fs30}>
                                        { t('featuredDestinations') } - { name }
                                    </h2>
                                </div>
                        }
                        <div className={classes.promotionContainer}>
                            {
                                promotions.map((ticket, index) => (
                                    <Promotion 
                                        key={index} 
                                        ticket={ticket} 
                                        idTransportationCompany={idTransportationCompany} 
                                        idEconomicGroup={idEconomicGroup} 
                                    />
                                ))
                            }
                        </div>
                    </>
            }
        </div>
    )
}
