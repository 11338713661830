import React, { useEffect, useState } from 'react';

import Loading from 'components/shared/loading';
import Deals from 'components/shared/deals';
import Content from 'components/shared/content';

import { useApplication } from 'hooks/application';
import useAxios from 'hooks/axios';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';

export default ({ seoBanner, endpoint, feature }) => {
    useStyles(classes);
    const axios = useAxios();
    const { culture, country, idPv } = useApplication();

    const [data, setData] = useState();

    const mapData = data => {
        return {
            deals: data.map(deal => {
                return {
                    link: deal.UrlRedireccion,
                    imageAlt: deal.AltImagen,
                    image: deal.Imagen,
                    subtitle: deal.Subtitulo,
                    title: deal.Titulo,
                    aditionalText: deal.TextoAdicional,
                    showButton: false
                }
            })
        }
    };

    useEffect(() => {
        const getData = async () => {
            const res = await axios.get(`${endpoint}?idPv=${idPv}&lang=${culture.iso_code}&pais=${country.url}`);
            
            if (res && res.data) {
                const mappedData = mapData(res.data);
                setData(mappedData);
            }
        }
        if (!feature) getData();
    }, []);

    useEffect(() => {
        if (feature) setData(seoBanner);   
    }, [seoBanner]);

    return (
        data
            ? <Deals customClasses={{ link: classes.link, bannerContainer: classes.bannerContainer }} data={data}>
                {
                    data.deals.map(deal => (
                        <Content
                            key={deal.link}
                            customClasses={{ 
                                content: classes.bannersContent, 
                                container: classes.contentContainer,
                                title: classes.title, 
                                subtitle: classes.subtitle,
                            }} 
                            params={{
                                subtitle: deal.subtitle,
                                aditionalText: deal.aditionalText,
                                title: deal.title,
                                showButton: deal.showButton
                            }}
                        />
                    ))
                }
              </Deals>
            : <Loading />
    )
}