import React from 'react';
import { useTranslation } from 'react-i18next';

import { SearchPicker, SearchSelect } from 'components/shared/searchbox';
import Input from 'components/specialJourneys/input';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import './translations';

export default ({ pickerId, selectName, selectedItem, textFieldName, handlerDate, handleChange, handleSelectChange }) => {
    useStyles(classes, globalStyles);
    const { t } = useTranslation('scheduleInformation');

    return (
        <div className={classes.container}>
            <div>
                <div className={classes.searchPicker}>
                    <SearchPicker 
                        id={pickerId}
                        customClasses={{ input: classes.datepicker }} 
                        field='date' 
                        placeholder={`${t('selectDate')} *`} 
                        onSelectItem={handlerDate} 
                    />
                </div>
            </div>
            <div>
                <SearchSelect
                    selectedItem={selectedItem}
                    placeHolder={t('departureMoment')} 
                    data={[
                        { name: `${t('morning')}`, id: 'Mañana' },
                        { name: `${t('afternoon')}`, id: 'Tarde' },
                        { name: `${t('evening')}`, id: 'Noche' },
                    ]}
                    onChange={handleSelectChange}
                    name={selectName}
                    required
                />
            </div>
            <div>
                <Input
                    required
                    label={t('schedule')}
                    name={textFieldName}
                    onBlur={handleChange}
                    placeholder={`${t('schedule')} *`}
                />
            </div>
        </div>
    )
}