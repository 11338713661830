export default {
    name: 'Nombre',
    email: 'example@example.com',
    chooseRating: 'Puntaje',
    bad: 'Malo',
    regular: 'Regular',
    good: 'Bueno',
    veryGood: 'Muy bueno',
    excellent: 'Excelente',
    yourComment: 'Comentario',
    send: 'Enviar',
    thanks: '¡Gracias por su opinión!',
    commentSentMessage: 'Tu comentario será publicado en breve',
}