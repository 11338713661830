export default {
    product: 'vuelos',
    searchTickets: 'Buscar vuelos',
    searchTicketsSeo: 'buscar',
    searchTicketsNow: 'Buscá tus vuelos baratos',
    search:'Buscar',
    from:'Origen',
    to:'Destino',
    departure: 'Partida',
    return:'Regreso',
    errorMessageSameStops: 'Origen y Destino no pueden ser iguales',
    inputPlaceholder: 'Ingrese ciudad',
    datePlaceholder: 'Seleccione fecha'
}