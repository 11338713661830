import React from 'react';

import Input from 'components/specialJourneys/input';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';

export default ({ title, names, required, labels, handleChange, showTitle = true }) => {
    useStyles(classes);

    return (
        <>
            {
                showTitle &&
                    <h3 className={classes.formSubtitle}>
                        { title }
                    </h3>
            }
            <div className={classes.container}>
                <div>
                    <Input
                        required={required[0]}
                        label={labels[0]}
                        placeholder={required[0] ? `${labels[0]} *` : labels[0]}
                        name={names[0]}
                        handleChange={handleChange}
                    />
                </div>
                <div>
                    <Input
                        required={required[1]}
                        label={labels[1]}
                        placeholder={required[1] ? `${labels[1]} *` : labels[1]}
                        name={names[1]}
                        handleChange={handleChange}
                    />
                </div>
                <div>
                    <Input
                        required={required[2]}
                        label={labels[2]}
                        placeholder={required[2] ? `${labels[2]} *` : labels[2]}
                        name={names[2]}
                        handleChange={handleChange}
                    />
                </div>
                <div>
                    <Input
                        required={required[3]}
                        label={labels[3]}
                        placeholder={required[3] ? `${labels[3]} *` : labels[3]}
                        name={names[3]}
                        handleChange={handleChange}
                    />
                </div>
            </div>
        </>
    )
}