import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";

import Pv from 'components/howToBuy/pvs/pv';
import { SearchSelect } from 'components/shared/searchbox';

import urls from 'shared/urls';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import './translations';

const Pvs = ({ data, history, onChange }) => {
    useStyles(classes, globalStyles);
    const { t } = useTranslation('pvs');

    const handleChange = value => {
        const city = data.cities.find(city => city.id === value);
        const name = urls.toSEOFriendly(city.name);
        const url = `/${t('personalPurchaseUrl')}/${value}/${name}`;
        history.push(url);
        if(onChange) onChange();
    };

    return (
        <div className={globalStyles.flexColumn}>
            <div className={`${classes.select} ${globalStyles.flex}`}>
                <div className={globalStyles.flexJustifyCenter}>
                    <h2 className={classes.title}> { t('search') } </h2>
                </div>
                <SearchSelect 
                    selectedItem={{ label: data.selectedCity.name, value: data.selectedCity.id }} 
                    placeHolder={t('selectCity')} 
                    data={data.cities} 
                    onChange={handleChange} 
                />
            </div>

            <div className={globalStyles.flexColumn}>
                {
                    data.pvs.map((item, index) => (
                        <div className={globalStyles.flexColumn} key={index}>
                            <div className={`${globalStyles.flexJustifyCenter} ${globalStyles.m20}`}>
                                <h2 className={`${globalStyles.fs30} ${globalStyles.fwBold} ${globalStyles.textCenter}`}> 
                                    { item.cityName } 
                                </h2>
                            </div>
                            {
                                item.pvsByDistrict.map((district, index) => (
                                    <div className={globalStyles.flex} key={index}>
                                        <div className={`${classes.district} ${globalStyles.flexJustifyCenter}`}>
                                            <span className={`${globalStyles.fs22} ${globalStyles.fwBold}`}> 
                                                { district.districtName } 
                                            </span>
                                        </div>
                                        {
                                            district.pvs.map((pv, index) => (
                                                <Pv pv={pv} key={index} />
                                            ))
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default withRouter(Pvs);