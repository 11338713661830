import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {  SearchPicker, SearchButton } from 'components/shared/searchbox';

import { generateUrl } from 'shared/c&t/searchbox';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import './translations';

const SelectFields = ({ history,  circuit }) => {
  useStyles(classes, globalStyles);
  const { t } = useTranslation('selectFields');

  const [trip, setTrip] = useState({ 
    departureId: circuit.departures[0].padId, 
    destinationId: circuit.destinations[0].padId,
    date: null
  });

  let translation = 'selectDeparture';
  let array = circuit && circuit.departures;
  let type = 'departureId';

  if(circuit && !circuit.isDestination) {
    translation = 'selectArrival';
    array = circuit.destinations;
    type = 'destinationId';
  }

  const goToServices = () => {
    const url = generateUrl(trip, t('services'), t('search'));
    history.push(url);
  }

	const handlerItemSelected = (item, elem) => setTrip({ ...trip, [elem]: item });
  
  return (
    <div className={`${classes.root} ${globalStyles.flexColumn}`}>
      <div className={`${classes.container} ${globalStyles.mtb10}`}>
        <div className={classes.selectPlace}>
          <div className={globalStyles.flexJustifyCenter}>
            <span className={classes.number}> 1.</span> { t(`${translation}`) }
          </div>
          <div className={`${globalStyles.flexColumn} ${globalStyles.flexJustifyCenter}`}>
          {
            array.map((item, index) => (
              <div className={`${classes.radioContainer} ${globalStyles.flexJustifyCenter}`} key={index}>
                <input 
                  type='radio' 
                  name='circuits' 
                  value={item.padId}
                  defaultChecked={index === 0}
                  onClick={() => handlerItemSelected(item.padId, type)}
                />
                <label className={classes.padName}> {item.padName} </label>
              </div>
            ))
          }
          </div>
        </div>
        <div className={classes.selectDate} container>
          <div className={`${globalStyles.mb10} ${globalStyles.flexJustifyCenter}`}>
            <span className={classes.number}> 2.</span> { t('selectDate') }
          </div>
          <div className={globalStyles.flexJustifyCenter}>
            <div className={classes.datepicker}>
              <SearchPicker 
                id='date' 
                field='date' 
                placeholder={t('selectDate')}
                onSelectItem={handlerItemSelected}
                initValue={trip.date}
                compareValue={trip.date}
                customClasses={{input: classes.input}} 
              />
            </div>
          </div>
        </div>
      </div>
      <div className={globalStyles.flexJustifyCenter}>
        <div className={`${classes.buttonContainer} ${globalStyles.flexJustifyCenter}`}>
          <SearchButton id='searchButton' disabled={false} type='submit' onClick={goToServices}>
            { t('select') }
          </SearchButton>
        </div>
      </div>
    </div>
  )
}

export default withRouter(SelectFields);