import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import Captcha from 'components/captcha';
import CustomDialog from 'components/shared/customDialog';
import Error from 'components/shared/error';
import Button from 'components/shared/button';
import { useApplication } from 'hooks/application';

import useAxios from 'hooks/axios';

import urls from 'shared/urls';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import './translations';

export default ({ name, endpoint }) => {
	useStyles(classes, globalStyles);
	const axios = useAxios();
	const { t } = useTranslation('form');
	const recaptchaInput = useRef();
	const nameInputRef = useRef();
	const emailRef = useRef();
	const textAreaRef = useRef();
	
	const [valid, setValid] = useState(false);
	const [recaptchaResponse, setRecaptchaResponse] = useState('');
	const [form, setForm] = useState({
		username: '',
		email: '',
		comment: '',
		rating: 0,
	});
	const [dialog, setDialog] = useState({
		openDialog: false,
		openError: false
	});

	const { country } = useApplication();

	useEffect(() => {
		validateForm(form);
	}, [recaptchaResponse, form]);

	const dropdownOptions = [
		{ value: 1, label: `⭐ ${t('bad')}` },
		{ value: 2, label: `⭐⭐ ${t('regular')}` },
		{ value: 3, label: `⭐⭐⭐ ${t('good')}` },
		{ value: 4, label: `⭐⭐⭐⭐ ${t('veryGood')}` },
		{ value: 5, label: `⭐⭐⭐⭐⭐ ${t('excellent')}}` },
	];

	const handleChange = name => event => setForm({ ...form, [name]: event.target.value });

	const handleRatingChange = rating => setForm({ ...form, rating });

	const verifyCallback = response => setRecaptchaResponse(response);
	const onDialogClose = () => {
		setDialog({ openDialog: false, openError: false });
		setForm({ username: '', email: '', comment: '', rating: 0 });
		setRecaptchaResponse('');
		recaptchaInput.current.reset();
		textAreaRef.current.value = '';
		nameInputRef.current.value = '';
		emailRef.current.value = '';
	};

	const handleClick = async e => {
		e.preventDefault();
		const data = {
			NombreLanding: name,
			NombreTerminal: urls.toSEOFriendly(name),
			NombreUsuario: form.username,
			Puntaje: form.rating.value,
			Comentario: form.comment,
			email: form.email,
			pais: country.url,
			'g-recaptcha-response': recaptchaResponse
		};

		try {
			const response = await axios.get(endpoint, { params: data });

			response.data.Error 
				? setDialog({ ...dialog, openError: true })
				: setDialog({ ...dialog, openDialog: true });

		} catch(ex) {
			setDialog({ ...dialog, openError: true })
		}
	}

	const validateForm = form => {
		const emailRegex = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const isFormValid = 
			recaptchaResponse.length &&
			form.username.length &&
			form.comment.length &&
			(form.rating !== 0 && parseInt(form.rating.value, 10)) > 0 &&
			(form.email && emailRegex.test(form.email));
		
		setValid(isFormValid);
	}

	return (
<form className={classes.container} noValidate autoComplete='off' onSubmit={handleClick}>
			<div className={classes.inputsContainer}>
				<div>
					<input
						placeholder={t('name')}
						onBlur={handleChange('username')}
						className={classes.input}
						ref={nameInputRef}
					/>
				</div>

				<div>
					<input
						placeholder={t('email')}
						onBlur={handleChange('email')}
						className={classes.input}
						ref={emailRef}
					/>
				</div>

				<div>
					<Dropdown 
						options={dropdownOptions} 
						onChange={handleRatingChange}
						value={form.rating}
						controlClassName={classes.dropdown}
						placeholder={t('chooseRating')}
					/>
				</div>
			</div>

			<div>
				<textarea
					placeholder={t('yourComment')}
					onBlur={handleChange('comment')}
					className={classes.textArea}
					ref={textAreaRef}
				/>
			</div>

			<div className={`${classes.formControl} ${classes.captcha}`}>
				<Captcha verifyCallback={verifyCallback} recaptchaInput={recaptchaInput}/>
			</div>

			<div className={globalStyles.flexJustifyCenter}>
				<Button
					id='submit'
					disabled={!valid}
					data-testid='submit'
				>
					{ t('send') }
				</Button>
			</div>

			{
				dialog.openDialog && 
					<CustomDialog 
						onClose={onDialogClose}
						title={t('thanks')}
					>
						{ t('commentSentMessage') }
					</CustomDialog>
			}
			{ dialog.openError && <Error error={true} onClose={onDialogClose} /> }
		</form>
	);
}