import React from 'react';
import { useTranslation } from 'react-i18next';

import Form from 'components/shared/comments/form';
import Comment from 'components/shared/comments/comment';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import './translations';

export default ({ name, comments, showRating, subtitle, endpoint }) => {
	useStyles(classes, globalStyles);
    const { t } = useTranslation('comments');

	const renderStars = () => {
		const ratings  = comments.map(x => x.rating);
		const averageRating = Math.round(ratings.reduce((a, b) => a+b, 0) / ratings.length);
		const stars = [];

		for(let i = 0 ; i < averageRating ; i++) {
			stars.push(<span role='img' aria-label='star' key={i}>⭐</span>)
		}
		return stars;
	}

	return (
		showRating 
			?  <div className={classes.root}>
					<div className={classes.wrapper}>
						<div className={classes.container}>
							<div>
								<h2 className={`${globalStyles.textCenter} ${globalStyles.mtb15} ${globalStyles.fw400}`}>
									{ t('title') }
									<strong> { name } </strong>
									{ renderStars() }
								</h2>
							</div>

							<div className={classes.body}>
								<div className={globalStyles.flexJustifyCenter}>
									<h3 className={`${globalStyles.mb10} ${globalStyles.fw400}`}>
										{ subtitle }
									</h3>
									<p dangerouslySetInnerHTML={{ __html: t('text')}} />
								</div>
								<div className={globalStyles.flex}>
									<Form name={name} endpoint={endpoint} />
								</div>
							</div>

							<div className={globalStyles.flexColumn}>
								<div className={globalStyles.textCenter}>
									<h2 className={classes.commentsTitle}>
										{ t('latestsComments') }
									</h2>
								</div>

								<div>
									{ 
										comments.length > 0 
											? comments.map((comment, index) => 
												<Comment comment={comment} key={index}/>
											)
											: <span className={globalStyles.textCenter}> { t('noCommentsYet') } </span> 
									}
								</div>
							</div>
						</div>
					</div>
				</div>
		: null
	);
}