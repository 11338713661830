// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._91171685260f839594d35c707443b580-scss{color:currentColor;width:100%;border:1px solid var(--grey-light);display:block;padding:10px 15px;background:none;transition:all 0.2s ease-in-out}._91171685260f839594d35c707443b580-scss:hover{border-color:var(--primary-main)}\n", ""]);
// Exports
exports.locals = {
	"input": "_91171685260f839594d35c707443b580-scss"
};
module.exports = exports;
