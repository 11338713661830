// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._848383a127437163ae3b1bcbdfe4488a-scss{color:var(--searchBox-button-font-color);font-size:15px;line-height:1.7;text-transform:uppercase}._1988efaa12811f584b6b79c4a66c1a5b-scss{display:grid;grid-gap:10px;grid-row:span 2}.cb167a50e2d126cfab5b92fe4c46284e-scss{display:grid;grid-template-columns:1fr 3fr}\n", ""]);
// Exports
exports.locals = {
	"labels": "_848383a127437163ae3b1bcbdfe4488a-scss",
	"container": "_1988efaa12811f584b6b79c4a66c1a5b-scss",
	"searchButton": "cb167a50e2d126cfab5b92fe4c46284e-scss"
};
module.exports = exports;
