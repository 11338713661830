const mapSeoBanners = data=> {
    if(!data || data.length == 0)  return { deals:[]};

    return {
        deals: data.map(x => {
            const { link,imagen,subtitulo,titulo,textoAdicional } = x;
            return { 
                link: link,
                imageAlt: imagen?.imagen[0]?.alternativeText,
                image: imagen?.imagen[0]?.url,
                subtitle: subtitulo,
                title: titulo,
                aditionalText: textoAdicional,
                showButton: false
            }
        })
    }
}

export default mapSeoBanners