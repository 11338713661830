const mapHotels = hotels => {
    return hotels.map(hotel => {
        const { Id, Nombre, NombreTipo, PrimeroEnGrupo, Tipo, TipoID } = hotel;
        
        return {
            id: Id,
            text: Nombre,
            typeName: NombreTipo,
            isFirst:  PrimeroEnGrupo,
            type: Tipo,
            typeId: TipoID
        }
    });
}

export { mapHotels }