import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import SearchBox from 'components/c&t/searchBox';
import FeatureDeal from 'components/shared/c&tBanners/featureDeal';
import Deals from 'components/shared/c&tBanners/deals';
import CustomHead from 'components/shared/customHead';
import SeoTextBanners from 'components/shared/seoTextBanners';
import NoSSR from 'components/shared/noSSR';

import { useApplication } from 'hooks/application'
import useContent from 'hooks/content';
import { CyTProvider, getIndex } from 'hooks/c&t';
import useRedis from 'hooks/cms/redis';
import mapHomeTransfers from 'hooks/cms/mappers/mapHomeTransfers';

import urls from 'shared/urls';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import './translations';

const Transfers = () => {
  useStyles(classes, globalStyles);
  const { t } = useTranslation('transfers');
  const { getDealsTransfer, getFeaturedTransfer } = useContent();

  const [featureDeal, setFeatureDeal] = useState();
  const [deals, setDeals] = useState([]);
  const [data, setData] = useState();
  const { metadata, destinations } = getIndex('ApiCircuitosTraslados/IndexTransfers');
  const { idPv } = useApplication();
  const { getCache } = useRedis();

  const enableStrapi = process.env.FT_ENABLE_STRAPI_HOME_TRANSFER;

  const getLink = deal => {
    const { circuitId, destinationId, title } = deal;
    const destination = destinations.find(destination => destination.id === destinationId);

    if (!destination) return '/';

    return circuitId === 0
      ? `/${t('transfers')}/${urls.toSEOFriendly(destination.name)}`
      : `/${t('transfers')}/${urls.toSEOFriendly(destination.name)}/${urls.toSEOFriendly(title)}`;
  }
  
  useEffect(() => {
    const getDeals = async () => {
      if(!metadata.UrlCanonica){
        metadata.UrlCanonica=window.location.href;
        }   

        try{
          setData({metadata, ...rest});
        }catch(ex){
          console.log(ex);
        }

      const deals = await getDealsTransfer();
      deals.forEach(deal => deal.link = getLink(deal));
      setDeals(deals);
    }

    const getContent = async () => {
      const cms = await getCache({key:'homeTransferQuery',idPv:idPv});
      const { deals,featureDeal,metadata,seoTextBox } = mapHomeTransfers(cms);

      featureDeal.link = getLink(featureDeal);
      deals.forEach(deal => deal.link = getLink(deal));

      setData({ deals,featureDeal,metadata,seoTextBox });
    }

    if (destinations.length > 0 && enableStrapi) {
        getContent();
    } else if(destinations.length > 0) {
        getDeals();
    }
  }, [destinations.length])

  useEffect(() => {
    const getFeatureDeal = async () => {
      const featureDeal = await getFeaturedTransfer();
      featureDeal.link = getLink(featureDeal);
      setFeatureDeal(featureDeal);
    }

    if (destinations.length > 0 && !enableStrapi) getFeatureDeal();
  }, [destinations.length])

  return (
    data
        ? <div className={classes.homeContainer}>
            <CustomHead metadata={data.metadata} />
            <div className={classes.search}>
              <div className={classes.searchbox}>
                <SearchBox 
                  getRedirectionUrl={getLink} 
                  title={t('searchboxTitle')} 
                  placeHolder={t('placeHolder')} 
                />
              </div>
              <div className={classes.dealsContainer}>
                <FeatureDeal featureDeal={data.featureDeal} />
              </div>
            </div>
            <NoSSR>
              <div className={classes.sectionsContainer}>
                <div className={`${classes.transfers} ${globalStyles.flex}`}>
                  <Deals deals={data.deals} />
                </div>
                <SeoTextBanners seoTextBox={data.seoTextBox} endpoint='ApiCircuitosTraslados/ObtenerTextosSEOTransfers' feature={process.env.FT_ENABLE_STRAPI_HOME_TRANSFER}/>
              </div>
            </NoSSR>
          </div>
        : <div className={classes.homeContainer}>
            <CustomHead metadata={metadata} />
            <div className={classes.search}>
              <div className={classes.searchbox}>
                <SearchBox 
                  getRedirectionUrl={getLink} 
                  title={t('searchboxTitle')} 
                  placeHolder={t('placeHolder')} 
                />
              </div>
              <div className={classes.dealsContainer}>
                <FeatureDeal featureDeal={featureDeal} />
              </div>
            </div>
            <NoSSR>
              <div className={classes.sectionsContainer}>
                <div className={`${classes.transfers} ${globalStyles.flex}`}>
                  <Deals deals={deals} />
                </div>
                <SeoTextBanners seoTextBox={null} endpoint='ApiCircuitosTraslados/ObtenerTextosSEOTransfers' feature={process.env.FT_ENABLE_STRAPI_HOME_TRANSFER}/>
              </div>
            </NoSSR>
          </div>
  );
}

export default () => {
  return (
    <CyTProvider>
      <Transfers />
    </CyTProvider>
  )
}