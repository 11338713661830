import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

// Component
import IncrementSelector from 'components/shared/IncrementSelector';
import Room from 'components/hotels/searchSelectRooms/room';
import { SearchLabel } from 'components/shared/searchbox';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import './translations';

const SearchSelectRooms = ({ onChange, maxValue, customClasses }) => {
    useStyles(classes, globalStyles);
    const { t } = useTranslation('searchSelectRooms');

    const [rooms, setRooms] = useState([{ adults: 2, childrens:0, ages:[], total: 2 }]);
    const [roomsCounter, setRoomsCounter] = useState(1);
    const isDisabled = rooms >= maxValue;

    const handlePlusClick = () => {
        rooms.push({ adults: 2, childrens:0, ages:[], total: 2 });
        setRooms(rooms);
        setRoomsCounter(roomsCounter + 1);
    }
    
    const handleMinusClick = () => {
        rooms.pop();
        setRooms(rooms);
        setRoomsCounter(roomsCounter - 1);
    }

    const getPeopleTotal = () => {
        let people = 0;
        rooms.forEach(room => {
            people += room.total;
        });

        return people;
    }
    
    const onRoomChange = (values, index) => {
        rooms[index] = values;
        setRooms(rooms);
        onChange({ total: getPeopleTotal(), rooms: roomsCounter, people: rooms });
    }

    const changeHandler = value => onChange({ total: getPeopleTotal(), rooms: value });

    return (
        <div className={`${classes.root} ${customClasses.root}`}>
            <div className={`${classes.selectorContainer} ${customClasses.selectorContainer}`}>
                <div className={customClasses.selector}>
                    <div>
                        <SearchLabel customClasses={{ labels: classes.label }} label={t('rooms')} htmlFor='adults' />
                    </div>
                    <div className={customClasses.middleDiv}>
                        <IncrementSelector 
                            maxValue={5} 
                            minValue={1} 
                            value={rooms.length} 
                            disabled={isDisabled}
                            onChange={value => changeHandler(value)} 
                            onPlusClick={handlePlusClick} 
                            onMinusClick={handleMinusClick} 
                        />  
                    </div>
                </div>
                <div className={globalStyles.flex}>
                {
                    rooms.map((room, index) => <Room maxValue={11} key={index} onChange={values => onRoomChange(values, index)} />)
                }
                </div>
            </div>
        </div>
    )
}

SearchSelectRooms.defaultProps = {
    maxValue: 1,
    onChange: () => { },
    customClasses:{
        root: '',
        middleDiv: '',
        selectorContainer:'',
        selector:'',
    }
}

export default SearchSelectRooms;