// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._0ef3607799150e6d030281aee14f29ad-scss{top:initial;width:100%}.de27e5f6f7c61825e870366a1a5b1f54-scss{display:block;position:relative;width:100%;padding:px;font-family:Lato, sans-serif}._3b05ab153e3921296a0641c13272298d-scss{padding:7px;font-size:15px;border:1px solid #ccc;width:100%}.ffaa5baf396264c4d4cbb44b874331d8-scss{padding:5px}.a21da46c77b58405436dcc380d45691b-scss{margin-top:15px}\n", ""]);
// Exports
exports.locals = {
	"menuDesplegado": "_0ef3607799150e6d030281aee14f29ad-scss",
	"customDropdown": "de27e5f6f7c61825e870366a1a5b1f54-scss",
	"styledSelect": "_3b05ab153e3921296a0641c13272298d-scss",
	"option": "ffaa5baf396264c4d4cbb44b874331d8-scss",
	"selectedOption": "a21da46c77b58405436dcc380d45691b-scss"
};
module.exports = exports;
