import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BusesSearchBox from 'components/buses/searchBox';
import Loading from 'components/shared/loading';
import FeaturedContent from 'components/landings/featuredContent';
import Comments from 'components/shared/comments';
import Information from 'components/landings/information';
import CustomHead from 'components/shared/customHead';
import Alternates from './alternates';

import { useApplication } from 'hooks/application';
import useRedis from 'hooks/cms/redis';
import { mapLanding,mapComments } from '/hooks/cms/mappers/mapLanding';

import useAxios from 'hooks/axios';
import useContent from 'hooks/content';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import './translations';

export default () => {
  useStyles(classes, globalStyles);
  const { t } = useTranslation('landing');
  const { landingName,idPv,country } = useApplication();
  const { getCache } = useRedis();
  const axios = useAxios();
  const { getLanding, getBusStops } = useContent();

  const [data, setData] = useState();
  
  useEffect(() => {
    const getData = async () => {
      
      const stops = await getBusStops();
      const landingStrapi = await getCache({ key:'landingQuery',link:landingName, idPv:idPv });
      const strapiContent = mapLanding(landingStrapi);

      const getComments = async (country) => {
        const { data } = await axios.get(`ApiLandings/ObtenerComentarios?nombre=${landingName}&pais=${country}`);

        return mapComments(data);
    };
      if(strapiContent){
        if(strapiContent.landing.showRating) strapiContent.comments = await getComments(country.url);

        setData({ landingData:strapiContent, stops });
      } else{
        const landingData = await getLanding();

        setData({ landingData, stops });
      }
    }

    getData();
  }, []);

  return (
    data
      ? <div className={classes.root}>
          <CustomHead metadata={data.landingData.landing.metadata} />
          <Alternates name={data.landingData.landing.title}/>
          <div className={`${classes.containers} ${globalStyles.flex}`}>
            <div className={`${classes.searchBox} ${globalStyles.flex}`}>
              <BusesSearchBox 
                redirectToService 
                searchQueryString={
                    data.landingData.landing.idEconomicGroup
                      ? `?idgrupoeconomico=${data.landingData.landing.idEconomicGroup}`
                      : `?idproveedor=${data.landingData.landing.idTransportationCompany}`
                } 
                stops={data.stops}
              />
            </div>
            <FeaturedContent landing={data.landingData.landing} />
          </div>
          <Information data={data.landingData.landing.description} />
          <Comments 
            name={data.landingData.landing.description.name} 
            comments={data.landingData.comments}
            showRating={data.landingData.landing.showRating} 
            subtitle={t('subtitle').replace('<name>', `${data.landingData.landing.description.name}`)}
            endpoint='ApiLandings/GuardarComentario'
          />
        </div>
      : <Loading />
  );
}