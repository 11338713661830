import React, { useState, useEffect } from 'react';

import CustomHead from 'components/shared/customHead';
import SearchBox from 'components/hotels/searchBox';
import DealsBanners from 'components/shared/dealsBanners';
import Deals from 'components/shared/c&tBanners/deals';
import SeoTextBanners from 'components/shared/seoTextBanners';
import NoSSR from 'components/shared/noSSR';

import useContent from 'hooks/content';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';

export default () => {
  useStyles(classes, globalStyles);
  const { getHotelsMetadata, getHotels } = useContent();

  const [data, setData] = useState();
  const [metadata, setMetadata] = useState({});

  useEffect(() => {
      const getData = async() => {
          const metadata = await getHotelsMetadata();
          const hotels = await getHotels();
          setData(hotels);
          setMetadata(metadata)
      };

      getData();
  }, []);

  return (
    <div className={classes.homeContainer}>
      <CustomHead metadata={metadata} />
      <div className={classes.search}>
        <div className={classes.searchbox}>
          <SearchBox />
        </div>
        <div className={classes.dealsContainer}>
          <DealsBanners endpoint='ApiHoteles/PromocionHotelesDestacada' />
        </div>
      </div>
      <NoSSR>
        {
          data && 
            <div className={`${classes.bannersContainer} ${globalStyles.flex}`}>
              <Deals deals={data} />
            </div>
        }
        <SeoTextBanners endpoint='ApiHoteles/ObtenerTextosSEOHome' />
      </NoSSR>
    </div>
  );
}