import React from 'react';
import { useApplication } from 'hooks/application';
import Reaptcha from 'reaptcha';

export default ({ verifyCallback, recaptchaInput }) => { 
  const { country } = useApplication();
  const isBr = country && country.url === 'br' ?true:false;
  const language = isBr?'pt-BR':'es-AR';
 return (
      <Reaptcha
        ref={recaptchaInput}
        sitekey={process.env.RecaptchaSiteKey}
        onVerify={verifyCallback}
        hl={language}
      />
  )
}