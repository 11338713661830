const validateForm = trip => {
    const { departure, destination, date, time } = trip;

    if (!departure || !destination || !date || !time) {
        return false;
    }

    if (departure.id === destination.id) {
        return false;
    }

    return true;
}

export { validateForm }