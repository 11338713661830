import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import BusesSearchBox from 'components/buses/searchBox';
import Promotion from 'components/landings/featuredContent/promotion';

import useContent from 'hooks/content';
import { ServiceProvider, searchServices } from 'hooks/services';
import { useApplication } from 'hooks/application';
import useRedis from 'hooks/cms/redis';
import mapSearchedTickets from 'hooks/cms/mappers/mapSearchedTickets';

import { mapTrip } from 'shared/services/common';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';

const Services = () => {
  useStyles(classes);
  const { departureName, destinationName, departurePadId, destinationPadId, departureDate, returnDate, adults, minors, infants } = useParams();
  const { getBusStops, getMostSearchedTickets } = useContent();
  const { features,name,idPv } = useApplication();
  const { getCache } = useRedis();
  const { services, setTrip } = searchServices();
  const enableStrapi = process.env.FT_ENABLE_SEARCH_TICKETS;
  const [mostSeachedTickets, setMostSeachedTickets] = useState([]);
  const [stops, setStops] = useState([]);

  useEffect(() => {
    const getData = async () => {
      if(!features.enableElasticSearch) {
        const stops = await getBusStops();
        setStops(stops);
      }
      const mostSearchedTickets = await getMostSearchedTickets();
      setMostSeachedTickets(mostSearchedTickets);
    }

    const getContent = async () => {
      const cms = await getCache({key:'ticketsMasBuscadosQuery',idPv:idPv, link:name});
      const cmsResult =await mapSearchedTickets(cms);
      setMostSeachedTickets(cmsResult);
  };

    if(enableStrapi) {
      getContent()
     }else{
         getData()};
 }, [])

  useEffect(() => {
    const params = { 
      departureName, destinationName, departurePadId, destinationPadId, departureDate, returnDate, adults, minors, infants 
    };

    const getData = async () => {
      const trip = await mapTrip(features.enableElasticSearch, stops, params);
      setTrip(trip);
    }

    getData();
  }, [stops, departureName, destinationName, departurePadId, destinationPadId, departureDate, returnDate, adults, minors, infants]);

  return (
    <div className={classes.container}>
      <div className={classes.leftColumn}>
        <div className={classes.searchbox}>
        <BusesSearchBox stops={stops} hasServices={services.length > 0} showGenericMode={true}/>
        </div>
      </div>
      <div className={classes.results}>
        <div className={classes.promotionContainer}> 
          {
            mostSeachedTickets && mostSeachedTickets.map((ticket, index) => (
              <Promotion 
                key={index} 
                ticket={ticket} 
                customClasses={{ 
                  banner: classes.banner, 
                  link: classes.bannerLink, 
                  promotion: classes.promotion 
                }}
              />
            ))
          }
        </div>
      </div>
    </div>
  )
}

const ServicesView = () => {
  return (
    <ServiceProvider>
      <Services />
    </ServiceProvider>
  )
}

export default ServicesView;