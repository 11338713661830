import React, { useEffect, useState } from 'react';

import Deals from 'components/shared/deals';
import Content from 'components/shared/content';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';

export default ({ featureDeal }) => {
    useStyles(classes);
    const [data, setData] = useState();

    const customClasses = { 
        deal: classes.deal,
        content: classes.content, 
        link: classes.link, 
        title: classes.title, 
        subtitle: classes.subtitle, 
        bannerContainer: classes.bannerContainer, 
        buttonContainer: classes.buttonContainer 
    }

    useEffect(() => {
        const mapData = () => {
            const data = {
                link: featureDeal.link,
                imageAlt: featureDeal.image.alt,
                image: featureDeal.image.url,
                showPrice: true,
                price: featureDeal.price,
                subtitle: featureDeal.subtitle,
                informativeText: featureDeal.informativeText,
                title: featureDeal.title
            };

            setData({ deals: [data] });
        }

        if (featureDeal) mapData();
    }, [featureDeal])

    return (
        data
            ? <Deals customClasses={customClasses} data={data} serverSide={true}>
                {
                    data.deals.map(deal => (
                        <Content
                            key={deal.link}
                            customClasses={customClasses} 
                            params={{
                                showPrice: deal.showPrice,
                                price: deal.price,
                                subtitle: deal.subtitle,
                                informativeText: deal.informativeText,
                                title: deal.title,
                            }}
                        />
                    ))
                }
              </Deals>
            : null
    )
};