export default {
    title:'Viajes Especiales',
    requiredFields: 'Campos obligatorios',
    numberOfPassengers: 'Cantidad de pasajeros',
    origin: 'Origen',
    destiny: 'Destino',
    departureFromTheOrigin: 'Salida desde el origen',
    departureFromTheDestination: 'Partida desde el destino',
    onceAtDestination: 'Una vez en destino',
    destinationMovements: '¿Movimientos en destino?',
    destinationMovementsDetail: 'Ingresá la descripción de los movimientos que van a hacer cuando lleguen a destino',
    carRemainWithTheGroup: '¿El coche debe permanecer con el grupo?',
    yes: 'Si',
    no: 'No',
    select: 'Seleccione',
    carCategory: 'Categoría del coche',
    catetingOnBoard: 'Catering a bordo',
    provideFoodToDrivers: '¿Alojan y proveen alimentos a los choferes?',
    passengerCharacteristics: 'Características de los pasajeros',
    institutionTitle: 'Institución para la que pide el servicio',
    institutionName: 'Nombre de la institución',
    contactInformation: 'Datos para contacto',
    nameAndSurname: 'Nombre y apellido',
    phoneContact: 'Teléfono de contacto',
    mailContact: 'Mail de contacto',
    observations: 'Observaciones',
    howDidYouMeetUs: '¿Cómo nos conoció?',
    sendRequest: 'Enviar solicitud',
    accept: 'Aceptar',
    escolaresJovenes: 'Escolares y/o Jovenes',
    adults: 'Adultos',
    elderly: 'Jubilados',
    families: 'Familias',
    other: 'Otros',
    coffeeJuice: 'Café / Jugo',
    foodOnBoard: 'Viandas',
    restaurantMeals: 'Comidas en parador',
    noCatering: 'Sin catering',
    bySearchEngines: 'Por buscadores de Internet (Google,Yahoo, Bing)',
    socialNetworks: 'Redes Sociales',
    onPlataforma10Com: 'En plataforma10.com',
    onPlataforma10Net: 'En plataforma10.net',
    trainingForSellers: 'Por capacitación para vendedores',
    atPointSale: 'En un Punto de venta de Plataforma 10',
    byMailWithNews: 'Por recibir un mail con la noticia',
    byReferred: 'Por conocidos o referidos',
    street: 'Calle',
    city: 'Ciudad',
    province: 'Provincia',
    country: 'País',
}