export default {
    title:'Special journeys',
    requiredFields: 'Required fields',
    numberOfPassengers: 'Number of passengers',
    origin: 'Origin',
    destiny: 'Destiny',
    departureFromTheOrigin: 'Departure from the origin',
    departureFromTheDestination: 'Departure from the destination',
    onceAtDestination: 'Once at destination',
    destinationMovements: 'Destination Movements',
    destinationMovementsDetail: 'Enter the description of the movements they will make when they arrive at destination',
    carRemainWithTheGroup: 'Should the car remain with the group?',
    yes: 'Yes',
    no: 'No',
    seleccione: 'Select',
    carCategory: 'Car category',
    catetingOnBoard: 'On board catering',
    provideFoodToDrivers: 'Do you provide food to drivers?',
    passengerCharacteristics: 'Passenger characteristics',
    institutionTitle: 'Institution for which the service is requested',
    institutionName: 'Name of the institution',
    contactInformation: 'Data for contact',
    nameAndSurname: 'Name and surname',
    phoneContact: 'Contact phone',
    mailContact: 'Contact mail',
    observations: 'Observations',
    howDidYouMeetUs: 'How did you meet us?',
    sendRequest: 'Send request',
    accept: 'Accept',
    escolaresJovenes: 'youth / students',
    adults: 'Adults',
    elderly: 'Elderly',
    families: 'Families',
    other: 'Other',
    coffeeJuice: 'Coffee / Juice',
    foodOnBoard: 'Food on board',
    restaurantMeals: 'Restaurant meals',
    noCatering: 'No catering',
    bySearchEngines: 'By Internet search engines (Google, Yahoo, Bing)', 
    socialNetworks: 'Social networks',
    onPlataforma10Com: 'On plataforma10.com',
    onPlataforma10Net: 'On plataforma10.net',
    trainingForSellers: 'For training for sellers',
    atPointSale: 'At a Platform 10 Point of Sale',
    byMailWithNews: 'By receiving a mail with the news',
    byReferred: 'By known the referred',
    street: 'Street',
    city: 'City',
    province: 'Province',
    country: 'Country',
}