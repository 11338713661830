import React from 'react';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';

export default ({ required, label, name, handleChange, placeholder, ...props }) => {
    useStyles(classes);

    return (
        <input
            required={required}
            label={label}
            name={name}
            onBlur={handleChange}
            placeholder={placeholder}
            className={classes.input}
            {...props}
        />
    )
}
