export default {
    product: 'remises',
    paySeo: 'pagar',
    pay: 'Pagar',
    title: 'Faça uma cotação para o seu traslado em carro',
    quote: 'Cotação',
    from:'Origem',
    to:'Destino',
    date: 'Fecha',
    time: 'Horário',
    price: 'Preço',
    errorMessageSameStops: 'Origem e Destino não podem ser iguais',
    inputPlaceholder: 'Insira endereço ou local',
    datePlaceholder: 'Selecione data',
    timePlaceholder: 'Selecione horário',
    noCoverageError: 'Não foram encontrados serviços disponíveis para a pesquisa',
    invalidDateError: 'A hora selecionada devem ser após as: ',
    title:'Atenção',
    comments: 'Observações',
    commentsPlaceholder: 'Aqui você deve inserir quaisquer comentários sobre a viagem, como número do apartamento',
    passengers: 'Passageiros'
}