import React from 'react';

import { useApplication } from 'hooks/application';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import moment from 'moment';

export default ({ comment }) => {
	useStyles(classes, globalStyles);
	const { culture:{ formatDate } } = useApplication();
	const rating = [...Array(comment.rating).keys()];

	return (
		<div className={classes.container}>
			<div className={`${globalStyles.flexColumn} ${globalStyles.ptb10}`}>
				<div>
					<span className={globalStyles.fs20}>
						{ comment.username }
					</span>
					<span>
						{
							rating.map(item => <span role='img' aria-label='star' key={item}>⭐</span>)
						}
					</span>
				</div>
				<span className={globalStyles.fs16}>
					{ moment(comment.date).format(formatDate) }
				</span>
			</div>
			<div className={globalStyles.flex}>
				<p className={globalStyles.fs16}> { comment.text } </p>
			</div>
		</div>
	);
}
