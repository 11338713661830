import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SearchLabel,
  SearchInputMaps,
  SearchPicker,
  SearchTimePicker,
  SearchButton,
  SearchContainer,
  SearchTextArea,
  SearchSelectPassenger,
} from 'components/shared/searchbox';
import ProcessingModal from 'components/shared/processingModal';

import CustomDialog from 'components/shared/customDialog';
import useAxios from 'hooks/axios';
import { useApplication } from 'hooks/application';

import { validateForm } from 'shared/cabs/searchbox';
import { errorCompare } from 'shared/searchBox/common';
import formatCurrency from 'shared/currency';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import moment from 'moment';
import './translations';

export default () => {
  useStyles(classes);

  const minimumAllowedMinutes = 480;
  const { culture } = useApplication();
  const { t } = useTranslation('cabsSearchbox');
  const axios = useAxios();

  const [trip, setTrip] = useState({});
  const [price, setPrice] = useState();
  const [formValid, setFormValid] = useState(false);
  const [showProcessingModal, setShowProcessingModal] = useState(false);
  const [showNoCoverageDialog, setShowNoCoverageDialog] = useState(false);
  const [showInvalidDateDialog, setShowInvalidDateDialog] = useState(false);


  const { departure, destination, date, time } = trip;

  useEffect(() => {
    const valid = validateForm(trip);
    setFormValid(valid);
    setPrice(null);
  }, [trip]);

  const handlerItemSelected = (item, elem) => {
    const newTrip = { ...trip };
    newTrip[elem] = item;

    setTrip(newTrip);
  };

  const onVerifyDateTime = (timeSelected) => {
    const isInvalidDate = timeSelected && (getMinimumAllowedDate() > getRequestDateByTimeSelected(timeSelected));
    setShowInvalidDateDialog(isInvalidDate);

    return !isInvalidDate;
  }

  const getRequestDateByTimeSelected = timeSelected => {
    const requestDate = date.toDate();
    requestDate.setHours(timeSelected.hour);
    requestDate.setMinutes(timeSelected.minute);

    return requestDate;
  };

  const getMinimumAllowedDate = () => {
    const minimumAllowedDate = new Date();
    minimumAllowedDate.setMinutes(minimumAllowedDate.getMinutes() + minimumAllowedMinutes);
    return minimumAllowedDate;
  }

  const handleSearch = event => {
    event.preventDefault();

    const form = document.querySelector('#form');
    document.querySelector('#departureAddress').value = trip.departure.text;
    document.querySelector('#departureLatitude').value = formatDouble(trip.departure.latitude);
    document.querySelector('#departureLongitude').value = formatDouble(trip.departure.longitude);
    document.querySelector('#destinationAddress').value = trip.destination.text;
    document.querySelector('#destinationLatitude').value = formatDouble(trip.destination.latitude);
    document.querySelector('#destinationLongitude').value = formatDouble(trip.destination.longitude);
    document.querySelector('#date').value = moment(trip.date).format('YYYY-MM-DD');
    document.querySelector('#schedule').value = trip.time;
    document.querySelector('#comments').value = trip.comments || null;
    document.querySelector('#passengers').value = trip.passengers;

    form.action = '/Remises/Checkout';
    form.method = 'POST';
    form.submit();
  };

  const getRequestDate = () => {
    const requestDate = date.toDate();
    const timeSplitted = time.split(":");

    const hours = timeSplitted[0];
    const minutes = timeSplitted[1];

    requestDate.setHours(hours);
    requestDate.setMinutes(minutes);

    return requestDate;
  }

  const formatDouble = item => item.toString().split('.').join(',');

  const getPrice = async event => {
    event.preventDefault();
    setShowProcessingModal(true);
    
    const requestDate = moment(getRequestDate()).format('YYYY-MM-DD HH:mm:ss');

    const price = await axios.post('Remises/GetPrice', {
      RequestDate: requestDate,
      Departure: {
        Address: trip.departure.text,
        Latitude: trip.departure.latitude,
        Longitude: trip.departure.longitude,
      },
      Destinations: [
        {
          Address: trip.destination.text,
          Latitude: trip.destination.latitude,
          Longitude: trip.destination.longitude,
        },
      ],
    });

    if (!price || !price.data || price.data.ViajeNoEncontrado) {
      setShowProcessingModal(false);
      setShowNoCoverageDialog(true);
      return;
    }
    setPrice({ value: formatCurrency(price.data.Valor, culture.iso_code), currency: price.data.Moneda });
    setShowProcessingModal(false);
  };

  const handlerPassengersChange = passengers => setTrip({ ...trip, ...passengers });

  return (
    <SearchContainer onSubmit={price ? handleSearch : getPrice} title={t('title')}>
      <input type='hidden' name='confirmacionViewModel.Departure.Address' id='departureAddress' />
      <input type='hidden' name='confirmacionViewModel.Departure.Latitude' id='departureLatitude' />
      <input type='hidden' name='confirmacionViewModel.Departure.Longitude' id='departureLongitude' />
      <input type='hidden' name='confirmacionViewModel.Destination.Address' id='destinationAddress' />
      <input type='hidden' name='confirmacionViewModel.Destination.Latitude' id='destinationLatitude' />
      <input type='hidden' name='confirmacionViewModel.Destination.Longitude' id='destinationLongitude' />
      <input type='hidden' name='confirmacionViewModel.Date' id='date' />
      <input type='hidden' name='confirmacionViewModel.Schedule' id='schedule' />
      <input type='hidden' name='confirmacionViewModel.Comments' id='comments' />
      <input type='hidden' name='confirmacionViewModel.Passengers' id='passengers' />

      <div className={classes.container}>
        <div className={classes.inputs}>
          <SearchLabel label={t('from')} htmlFor='departure' iconName='map-marker-alt' />

          <SearchInputMaps
            id='departure'
            placeholder={t('inputPlaceholder')}
            onSelectItem={handlerItemSelected}
            validateTrip={errorCompare(trip, t('errorMessageSameStops'))}
            initValue={departure}
          />
        </div>

        <div className={classes.inputs}>
          <SearchLabel label={t('to')} htmlFor='destination' iconName='map-marker-alt' />
          <SearchInputMaps
            id='destination'
            placeholder={t('inputPlaceholder')}
            onSelectItem={handlerItemSelected}
            validateTrip={errorCompare(trip, t('errorMessageSameStops'))}
            initValue={destination}
          />
        </div>

        <div className={`${classes.inputs} ${classes.inputDate}`}>
          <SearchLabel label={t('date')} htmlFor='date' iconName='calendar-alt' />
          <SearchPicker
            id='date'
            placeholder={t('datePlaceholder')}
            onSelectItem={handlerItemSelected}
            initValue={date}
          />
        </div>

        <div className={`${classes.inputs} ${classes.inputTime} ${date ? '' : classes.disabled}`}>
          <SearchLabel label={t('time')} htmlFor='time' iconName='calendar-alt' />
          <SearchTimePicker
            id='time'
            placeholder={t('timePlaceholder')}
            onSelectItem={handlerItemSelected}
            onVerify={onVerifyDateTime}
            initValue={time}
          />
        </div>

        <div className={classes.inputs}>
          <SearchLabel label={t('passengers')} htmlFor='passengers' iconName='users' />
          <SearchSelectPassenger
            customClasses={{ selectorContainer: classes.selectorContainer }}
            maxValue={4}
            onChange={handlerPassengersChange}
          />
        </div>

        <div className={classes.inputs}>
          <SearchLabel label={t('comments')} htmlFor='comments' iconName='info-circle-solid' />
          <SearchTextArea
            id='comments'
            placeholder={t('commentsPlaceholder')}
            onSelectItem={handlerItemSelected}
          />
        </div>
        {
          price &&
          <div className={classes.inputs}>
            <SearchLabel label={t('price')} htmlFor='price' iconName='money' />
            <div className={classes.price}>
              <h1>
                {price.currency} {price.value}
              </h1>
            </div>
          </div>
        }
      </div>
      <SearchButton id='searchButton' disabled={!formValid} type='submit'>
        {price ? t('pay') : t('quote')}
      </SearchButton>

      { showProcessingModal && <ProcessingModal />}

      {showNoCoverageDialog && <CustomDialog title={t('title')} onClose={() => { setShowNoCoverageDialog(false) }}>
        <span>{t('noCoverageError')}</span>
      </CustomDialog>}

      {showInvalidDateDialog && <CustomDialog title={t('title')} onClose={() => { setShowInvalidDateDialog(false) }}>
        <span>{`${t('invalidDateError')} ${getMinimumAllowedDate().toLocaleTimeString()}.`}</span>
      </CustomDialog>}
    </SearchContainer>
  );
};
