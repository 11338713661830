import mapDeal from "./mapDeal";
import mapMetaData from "./mapMetaData";
import mapSeoTextBanners from "./mapSeoTextBanners";

const getFeatureDeal = data => {
    const { oferta,detalle,dias } = data;
    const deal = mapDeal(oferta);

    return {
        ...deal,
        subtitle: detalle,
        informativeText: dias
    }
}

const mapHomeCircuits = data => {
    if(!data?.homeCircuits || data.homeCircuits.length == 0) return null;
    const { circuitoDestacado,circuitos,metadata,cajasSeo } = data.homeCircuits[0];

    return{
        deals:circuitos.map(oferta => mapDeal(oferta)),
        featureDeal:getFeatureDeal(circuitoDestacado),
        metadata:mapMetaData(metadata),
        seoTextBox:mapSeoTextBanners(cajasSeo)
    };
};

export default mapHomeCircuits;