// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._56a635b98ccc59c0ff1630219b13df03-scss{background:var(--grey-light);display:flex;justify-content:center;border-top-left-radius:10px;border-top-right-radius:10px}._176c2c4e8dd4fccc3f0340bdea5ce24d-scss{color:#999;font-weight:bold}._1a25cb71bbf8826a5de7c1161e6d6ade-scss{color:var(--black-dark)}.d3ea4415b58b87b0afb0567526ce7052-scss{color:var(--black-dark);padding:5px}.f1d770ff211e36815447bd7d041aa2f6-scss{display:flex;flex-direction:column;border:1px solid var(--grey-medium);border-radius:10px;margin-bottom:15px}._5b7e1854f165cfae74a7be785ae56438-scss{display:grid;grid-template-columns:repeat(2, 1fr)}._434d434b73cfc7d0f94e80a66f28938f-scss{display:flex;padding:5px;align-items:center}\n", ""]);
// Exports
exports.locals = {
	"roomContainer": "_56a635b98ccc59c0ff1630219b13df03-scss",
	"roomsLabel": "_176c2c4e8dd4fccc3f0340bdea5ce24d-scss",
	"label": "_1a25cb71bbf8826a5de7c1161e6d6ade-scss",
	"childrenAgeLabel": "d3ea4415b58b87b0afb0567526ce7052-scss",
	"passengersContainer": "f1d770ff211e36815447bd7d041aa2f6-scss",
	"selector": "_5b7e1854f165cfae74a7be785ae56438-scss",
	"middleDiv": "_434d434b73cfc7d0f94e80a66f28938f-scss"
};
module.exports = exports;
